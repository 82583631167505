import React from "react";
import { Link } from "react-router-dom";
import SubmissionSwiper from "./SubmissionSwiper/SubmissionSwiper";

function SubmissionsHome() {
    return (
        <div className="pt-[10vh] relative bg-black text-white flex flex-col items-center text-center overflow-x-hidden">
            <main>
                <div className="submissions-header flex items-baseline justify-center">
                    <h1 className="text-6xl font-sans">SUBMISSIONS 2024</h1>
                </div>
                <div className="mt-5 flex flex-col items-center w-full">
                    <SubmissionSwiper />
                    <button className="text-2xl text-white border-2 border-white rounded-lg py-2 px-4 mx-auto">
                        <Link to="/submission">See All &gt;&gt;</Link>
                    </button>        
                </div>
            </main>
        </div>
    )
}

export default SubmissionsHome;
