import { useNavigate } from "react-router-dom";
import { Film } from "../../Data/films";
import FilmCard from "../Submission/FilmCard";
import { useState } from "react";

interface NominationSectionProps {
    title: string;
    nominationList: Film[];
}

const NominationSection = ({...props}: NominationSectionProps) => {
    return (
        <div>
            <div className="flex max-lg:justify-center">
                <h2 className="text-5xl text-light-gold hover:shadow-lg hover:shadow-light-gold rounded-lg p-4 font-bold w-96 max-lg:text-center">
                    {props.title}
                </h2>    
            </div>
            <div className="flex items-center ">
                <div className="flex flex-wrap justify-left max-lg:justify-center">
                    {props.nominationList.map((film, index) => (          
                        <FilmCard
                            key={film.name}
                            film={film}
                        />       
                    ))}
                </div>
            </div>
            
        </div>
          
    )
}

export default NominationSection;