import { motion } from "framer-motion";
import { Film } from "../../../../Data/films";
import { useState } from "react";
import { useNavigate } from "react-router";

type FilmCardProps = {
    film: Film;
};

function convertToThumbnailUrl(googleDriveUrl: string, width = 1000): string {
    let fileId;

    const matchPath = googleDriveUrl.match(/\/d\/(.+?)\//);
    if (matchPath) {
        fileId = matchPath[1];
    } else {
        const matchQuery = googleDriveUrl.match(/id=([^&]+)/);
        if (matchQuery) {
            fileId = matchQuery[1];
        }
    }

    if (!fileId) {
        console.error('Invalid Google Drive URL:', googleDriveUrl);
        return googleDriveUrl;
    }

    return `https://drive.google.com/thumbnail?id=${fileId}&sz=w${width}`;
}



function TestFilmCard({ ...props }: FilmCardProps) {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    const handleFilmClick = (film: Film) => {
        navigate('/synopsis', { state: { film } });
    }

    const fadeInAnimationVariants = {
        initial: {
            opacity: 0,
            y: 100,
        },
        animate: {
            opacity: 1,
            y: 0,
        }
    }

    const thumbnailUrl = convertToThumbnailUrl(props.film.poster);

    return (
        <motion.div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => handleFilmClick(props.film)}
            className="hover:shadow-xl hover:shadow-white"
            variants={fadeInAnimationVariants}
            initial={{ opacity: 0, x: -150 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
        >
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: isHovered ? 1 : 0 }}
                transition={{ duration: 0.3 }}
                className=""

            >
                <h1>{props.film.name.toUpperCase()}</h1>
                <p>
                    {Array.isArray(props.film.directors)
                        ? props.film.directors.map((dir, index) => <span key={index}>{dir.name}</span>)
                        : props.film.directors.name}
                </p>
            </motion.div>
            <img src={thumbnailUrl} alt={`${props.film.name} Poster`} className="" />
        </motion.div>
    );
}

export default TestFilmCard;