// SwiperComponent.js
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import { Link, useNavigate } from 'react-router-dom';
import { backwardArrow, forwardArrow } from '../../../../Data/export_images';

import {
  Film,
  cinemaMovies,
  featureFilms,
  internationalAnimation,
  internationalDocumentary,
  shortFeatureFilms,
  under18Films
} from '../../../../Data/films'; // Import all the film groups

const filmGroups = [
  ...cinemaMovies,
  ...featureFilms,
  ...internationalAnimation,
  ...internationalDocumentary,
  ...shortFeatureFilms,
  ...under18Films
];

const SubmissionSwiper = () => {
  const [hoveredFilm, setHoveredFilm] = useState<Film | null>(null);
  const navigate = useNavigate();

  const handleFilmHover = (film: Film) => {
    setHoveredFilm(film);
  };

  const handleMouseLeave = () => {
    setHoveredFilm(null);
  };

  const handleClick = (film: Film) => {
    navigate("/synopsis" + film.filmId);
  };

  return (
    <div className="container mt-8 flex justify-center items-center px-10">

      <div className="custom-swiper-button-prev z-10 cursor-pointer">
        <img src={backwardArrow} alt="Previous Slide" className="w-20 h-20"/>
      </div>
      
      <Swiper
        modules={[Navigation, Autoplay]}
        slidesPerView={4}
        slidesPerGroup={1}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        navigation={{
          nextEl: '.custom-swiper-button-next',
          prevEl: '.custom-swiper-button-prev',
        }}
        breakpoints={{
          600: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 5,
            centeredSlides: true,
          },
          900: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 5,
            centeredSlides: true,
          },
          1200: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 5,
            centeredSlides: false,
          },
        }}
        style={{ width: '100%'}}
      >
        {filmGroups.slice(0, 20).map((film: Film, index: number) => (
          <SwiperSlide key={index} style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              className="relative p-4 flex items-center justify-center"
              style={{ width: '351px', height: '452px', overflow: 'hidden', margin: '10px' }}
              onMouseEnter={() => handleFilmHover(film)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClick(film)}
            >
              {hoveredFilm === film ? (
                <div className="absolute inset-0 bg-black-100 text-dark-gold font-times font-bold flex flex-col items-center justify-center h-full w-full">
                  <Link to={"/synopsis" + film.filmId} className="w-full h-full flex flex-col items-center justify-center">
                    <img
                      src={film.poster}
                      alt={`${film.name} Poster`}
                      className="w-full h-full absolute inset-0 bg-center opacity-10 z-0"
                    />
                    <p className="flex text-center font-bold text-3xl">{film.name}</p>
                  </Link>
                </div>
              ) : (
                <div className="w-full h-full">
                  <img
                    src={film.poster}
                    alt={`${film.name} Poster`}
                    className="w-full h-full mb-2 font-bold transition-transform duration-600 rounded-md"
                  />
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="custom-swiper-button-next z-10 cursor-pointer">
        <img src={forwardArrow} alt="Next Slide" className="w-20 h-20"/>
      </div>

    </div>
  );
};

export default SubmissionSwiper;
