import { motion, useMotionValue } from "framer-motion";
import { useState, FC } from "react";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import { Film } from "../../../../Data/films";
// import FilmCard from "../../../Submission/FilmCard";
import TestFilmCard from "./TestFilmCard";

interface CarouselProps {
  films: Film[];
}

const FilmCardContainer:FC<CarouselProps> = ({films}) => {
  return(
    <>
      {films.map((film, index) => {
        return (
          <TestFilmCard key={film.name} film={film} />
        );
      })}
    </>
    
  )
}

interface SlidesProps {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  films: Film[];
}

const Slides = ({current, setCurrent, films} : SlidesProps) => {
  const DRAG_BUFFER = 50;

  const dragX = useMotionValue(0);


  const onDragEnd = () => {
    const x = dragX.get();

    if (x <= -DRAG_BUFFER && current < films.length - 1) {
      setCurrent((current) => current + 1);
    } else if (x >= DRAG_BUFFER) {
      if (current === 0) setCurrent(films.length - 1);
      else setCurrent((current) => current - 1);
    }
  }

  return (
    <div className="overflow-hidden relative gap-y-20 bg-black rounded-xl">
      <motion.div
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        animate={{ translateX: -current * 100 + "%" }}
        className={`flex transition ease-out duration-40`}
        onDragEnd={onDragEnd}
        style={{ x: dragX }}      
      >
        <FilmCardContainer films={films} />
      </motion.div>
    </div>
  );
}

interface DotsProps {
  films: Film[];
  setCurrent: (i: number) => void;
  current: number;
}

const Dots = ({films, setCurrent, current}: DotsProps) => {
  return ( 
    <div className="py-4 flex justify-center gap-3 w-full">
      {films.map((s, i) => {
        return (
          <div
            onClick={() => {
              setCurrent(i);
            } }
            key={"circle" + i}
            className={`rounded-full w-5 h-5 cursor-pointer  ${i === current ? "bg-white" : "bg-gray-500"}`}
          ></div>
        );
      })}
    </div>
  );
}

const CarouselMoviesTest: FC<CarouselProps> = ({ films }) => {
  let [current, setCurrent] = useState<number>(0);

  let previousSlide = () => {
    if (current === 0) setCurrent(films.length - 1);
    else setCurrent(current - 1);
  };

  let nextSlide = () => {
    if (current === films.length - 1) setCurrent(0);
    else setCurrent(current + 1);
  };

  return (
    <div className="flex items-center gap-x-10">
      <button onClick={previousSlide} className="w-10 h-10">
        <BsFillArrowLeftCircleFill size={40}/>
      </button>
      <div className="flex flex-col">
        <Slides current={current} setCurrent={setCurrent} films={films} />
        <Dots films={films} setCurrent={setCurrent} current={current} />
      </div>
      <button onClick={nextSlide}>
        <BsFillArrowRightCircleFill size={40}/>
      </button>
    </div>
    
  );
}

export default CarouselMoviesTest;

