import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
import casts, { MovieCast, createMoviesCastList } from "../Data/casts";
import { Film, allFilms, allFilmsWithIds } from '../Data/films';
import { missingImage } from '../Data/export_images';

const fetchFilmById = (filmList: Film[], id: number) => {
  return filmList.filter(film => film.filmId == id);
}

function Synopsis() {
  // const location = useLocation();
  // const { film }: { film: Film } = location.state || { film: {} };
  const {filmId} = useParams();
  const rootPath = window.location.origin;
  const film = fetchFilmById(allFilmsWithIds, parseInt(filmId!))[0];
  const navigate = useNavigate();
  // const history = useHistory

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log(film);
    // console.log(rootPath);
  }, []);

  const handleBackClick = () => {
    // console.log("Back clicked");
    navigate(-1);
  };

  const handleReadMoreClick = () => {
    setIsExpanded(true);
  };

  const handleModalClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).className.includes('modal-background')) {
      setIsExpanded(false);
    }
  };


  const handleCastClick = (castMember: Partial<MovieCast>) => {
    const movieCastList = createMoviesCastList(casts);
    const currentMovie = movieCastList.find(movie => movie.movieName.trim().toLowerCase() === film.name.trim().toLowerCase());

    if (currentMovie) {
      // Filter out the clicked castMember to create the otherCastAndCrew array
      const otherCastAndCrew = currentMovie.casts.filter(member => member.castName !== castMember.castName);
      //console.log("otherCastAndCrews11:", otherCastAndCrew);

      // Pass the selected castMember, the filtered otherCastAndCrew, and the movie name
      navigate("/cast", { state: { castMember, otherCastAndCrew, movieName: film.name.trim() } });
    } else {
      console.warn("Movie not found or no casts available");
    }
  };

  // Filter the main cast and crew based on the selected film name
  const filteredCastAndCrew = casts.filter(
    (castMember) => castMember.filmName.trim().toLowerCase() === film.name.trim().toLowerCase()
  );

  // Combine the main cast and crew with the otherCastAndCrew members
  const combinedCastAndCrew = filteredCastAndCrew.reduce((acc: Partial<MovieCast>[], castMember) => {
    // Add the main cast member
    acc.push({
      castName: castMember.castName,
      roles: castMember.roles,
      image: castMember.image,
      bio: castMember.bio,
    });

    // Add any other cast and crew members
    if (castMember.otherCastAndCrews && Array.isArray(castMember.otherCastAndCrews)) {
      castMember.otherCastAndCrews.forEach(other => {
        acc.push({
          castName: other.castName,
          roles: other.roles,
          image: other.image,
          bio: other.bio,
        });
      });
    }


    return acc;
  }, []);


  function convertToThumbnailUrl(googleDriveUrl: string, width = 1000): string { // Modify the Image clarity by editing width
    let fileId;
    const matchPath = googleDriveUrl.match(/\/d\/(.+?)\//);

    if (matchPath) {
      fileId = matchPath[1];
    } else {
      const matchQuery = googleDriveUrl.match(/id=([^&]+)/);

      if (matchQuery) {
        fileId = matchQuery[1];
      }
    }
    if (!fileId) {
      console.error('Invalid Google Drive URL:', googleDriveUrl);
      return googleDriveUrl;
    }

    return `https://drive.google.com/thumbnail?id=${fileId}&sz=w${width}`;
  }

  // console.log(missingImage)

  return (
    <div>
      {film.background && (
        // <div className="bg-no-repeat bg-cover bg-center h-screen relative bg-black"
        //   style={{
        //     backgroundImage: `url(${convertToThumbnailUrl(film.background)})`
        //   }}
        // >
        <div className="bg-no-repeat bg-cover bg-center h-screen relative bg-black"
          style={{
            backgroundImage: `url(${rootPath + '/' +  film.background})`
          }}
        >
          {/* <nav className="flex justify-center items-center w-full h-[10vh] shadow-[0_4px_30px_rgba(0,0,0,0.1)] backdrop-blur-[10.9px] m-0 bg-[rgba(74,46,46,0.8)]"> */}
            <Navbar />
          {/* </nav> */}
          <button className="absolute shadow-[0_4px_30px_rgba(0,0,0,0.1)] backdrop-blur-[10.9px] z-10 cursor-pointer transition-[background-color] duration-[0.3s] ease p-4 rounded-[5px] left-[2%] top-[12%] hover:bg-[rgba(29,29,29,0.6)] bg-[rgba(29,29,29,0.8)]"
          onClick={handleBackClick}>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 fill-[white]" viewBox="0 0 24 24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" /></svg>
          </button>
          <div className="absolute w-full h-1/4 shadow-[0_4px_30px_rgba(0,0,0,0.1)] backdrop-blur-[10.9px] text-[#f0e6d2] flex justify-around items-center flex-wrap box-border p-8 bottom-0 bg-[rgba(29,29,29,0.8)]">
            <div className="text-center flex-1 px-4 py-0">
              <h1 className="text-[2.5vw] font-bold font-['Arial'] text-[#CFA14E] mb-4">{film.name}</h1>
            </div>
            <div className="text-center flex-1 px-4 py-0">
              <h2 className="text-[1.6vw] font-bold font-['Arial'] mb-4">Film Details</h2>
              <p className="text-[0.8vw] font-medium leading-[1.6]">Directed by:
                {Array.isArray(film.directors)
                  ? film.directors.map(
                    (dir, index) => " " + dir.name + ", "
                  )
                  : <p>{film.directors.name}</p>
                }
              </p>
              <p className="text-[0.8vw] font-medium leading-[1.6]">Year of production: {film.year || "Year not available"}</p>
              <p className="text-[0.8vw] font-medium leading-[1.6]">Country: {film.country || "Country not available"}</p>
              <p className="text-[0.8vw] font-medium leading-[1.6]">Duration: {film.duration || "Duration not available"}</p>
            </div>
            <div className="text-center flex-1 px-4 py-0">
              <h2 className="text-[1.6vw] font-bold font-['Arial'] mb-4">Synopsis</h2>
              <p className="text-[0.8vw] font-medium leading-[1.6]">
                {film.synopsis ? (
                  film.synopsis.length > 300 ? (
                    <>
                      {film.synopsis.slice(0, 300)}
                      <span>... </span>
                      <button onClick={handleReadMoreClick} className="bg-transparent text-[#CFA14E] cursor-pointer text-base underline p-0 border-[none] hover:text-[#A67C33]">
                        Read more
                      </button>
                    </>
                  ) : (
                    film.synopsis
                  )
                ) : (
                  "Film synopsis not available."
                )}
              </p>
            </div>
          </div>
        </div>
      )}

      {isExpanded && (
        <div className="fixed w-full h-full bg-[rgba(0,0,0,0.8)] flex justify-center items-center z-[1000] left-0 top-0" onClick={handleModalClose}>
          <div className="bg-[rgba(29,29,29,0.8)] shadow-[0_4px_15px_rgba(0,0,0,0.5)] max-w-[90%] max-h-[80%] overflow-y-auto text-[#f0e6d2] p-8 rounded-[10px]">
            <h2 className="mt-0">Full Synopsis</h2>
            <p className="leading-[1.6]">{film.synopsis}</p>
          </div>
        </div>
      )}

      <main className="bg-black p-8">
        <h2 className="text-2xl text-[#f0e6d2] font-medium text-center">Cast & Crew</h2>
        <div className="flex overflow-x-auto gap-4 px-0 py-4 cast-list">
          {combinedCastAndCrew.map((castMember, index) => (
            <div key={index} className="flex-[0_0_auto] w-[150px] text-center text-white cursor-pointer" onClick={() => handleCastClick(castMember)}>
              <div className="w-full h-[200px] flex justify-center items-center overflow-hidden rounded-[5px]">
                <img src={rootPath + "/" + (castMember.image || missingImage)} alt={castMember.castName} className="max-w-full max-h-full object-cover grayscale" />
              </div>
              <p className="font-bold mt-2">{castMember.castName}</p>
              <p className="text-[0.9rem] text-[#aaa] mt-1">
                {Array.isArray(castMember.roles) ? castMember.roles.join(' ') : castMember.roles}
              </p>
            </div>
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Synopsis;
