import CarouselMoviesTest from "../Components/Home/SubmissionsHome/SubmissionSwiper/CarouselMoviesTest";
import CarouselTest from "../Components/Home/SubmissionsHome/SubmissionSwiper/CarouselTest";
import { cinemaMovies, featureFilms, internationalAnimation, internationalDocumentary, shortFeatureFilms, under18Films } from "../Data/films";

const filmGroups = [
  ...cinemaMovies,
  ...featureFilms,
  ...internationalAnimation,
  ...internationalDocumentary,
  ...shortFeatureFilms,
  ...under18Films
];

const TestPage = () => {
  let slides = [
    "https://i.pinimg.com/originals/51/82/ac/5182ac536727d576c78a9320ac62de30.jpg",
    "https://wallpapercave.com/wp/wp3386769.jpg",
    "https://wallpaperaccess.com/full/809523.jpg",
    "https://getwallpapers.com/wallpaper/full/5/c/0/606489.jpg",
  ];

  const films = filmGroups.slice(0, 10);

  return (
    <>
      <CarouselTest slides={slides}/>
      <CarouselMoviesTest films={films}/>
    </>
  );
}

export default TestPage;