// casts.js

import {
    ArchiesDirector, 
    DaalRotiDirector, 
    DreamDirector, 
    HR2Director, 
    IRaHDirector, 
    MeantimeDirector, 
    TaarikhDirector, 
    TheNoteDirector, 
    UndercoverDirector, Undercover_H, Undercover_I, Undercover_Mahidul, Undercover_Mahmudul, Undercover_Nabila, Undercover_Nor, Undercover_R, Undercover_S, 
    WhitePanjabDirector, 
    GingersnapChristmasDirector, 
    AbahAndHisBandDirector, 
    DarwinDirector, 
    InHalfDirector, 
    QBJNDirector, 
    RobotDirector, 
    Betelgeuse_Director, 
    TheGarmentologistDirector, 
    missingImage, 
    TweenbotDirector, 
    ColebrookDirector, 
    LilithDirector, LilithLeadM, LilithCinematographer, 
    BackToRealityAndrew, BackToRealityBrooke, BackToRealityDirector, BackToRealityJana, BackToRealityKath, BackToRealityLisa, 
    LetMeOutLeadActor, LetMeOutSoundDesigner, 
    CABTKTDirector, 
    DreamweaversDirector, 
    FlintFatigueDirector, 
    FreelandDirector, 
    TheHeroOfAdelaideDirector, TheHeroOfAdelaideLead, TheHeroOfAdelaideCinema, TheHeroOfAdelaideSupporting, 
    WDSRALead, WDSRASupporting,
    ThePicadillyDirector, ThePicadillyProducer, ThePicadillyLead_Sch, ThePicadillyLead_Tch,
    SoleSistaDirector, SoleSistaLead, SoleSistaSupporting_CM, SoleSistaSupporting_SP, SoleSistaSupporting_CW, SoleSistaSupporting_TP, SoleSistaCinematographer, SoleSistaEditor,
    ThePodDirector, ThePodProducer,
    HEDirector,
    MyHandsAreShakingDirector,
    BlackFridayDirector, BlackFridayProducer, BlackFridayScreenwriter, BlackFridayActor_B, BlackFridayActor_T, BlackFridayActor_Q,
    MindTheCartDirector_MD, MindTheCartDirector_NSP, MindTheCartSupporting, MindTheCartEditor,
    BForNaooDirector,
    ParoleDirector,
    GhostTownCricketDirector, GhostTownCricketProducer_H, GhostTownCricketProducer_B, GhostTownCricketCinematographer, GhostTownCricketActor_S, GhostTownCricketActor_H,
    OPIADirector, OPIAProducer, OPIALead, OPIASupport,
    CelebrationABlissParadeDirector, CelebrationABlissParadeProducer_R, CelebrationABlissParadeCinematographer, CelebrationABlissParadeEditor, CelebrationABlissParadeMusic, CelebrationABlissParadeDesigner,
    InTheTrenchesDirector,
    InvisiblesMéxicoDirector,
    JoyOfArtDirector,
    LaGrandeSignoraDirector_M, LaGrandeSignoraDirector_V, 
} from "./export_images"

type SocialLinks = {
    instagram?: string;
    facebook?: string;
    twitter?: string;
    youtube?: string;
    linkedin?: string;
    blog?: string;
    website?: string;
};

export type MovieCast = {
    filmName: string;
    castName: string;
    image: string;
    roles: string[] | string;
    quote: string;
    bio: string | {instagram: string};
    socialLinks: SocialLinks;
    otherCastAndCrews?: Partial<MovieCast>[];
};

const casts: MovieCast[] = [
    {
        filmName: "The Archíes",
        castName: "Zoya Akhtar",
        image: ArchiesDirector,
        roles: ['Director', 'Producer', 'Screenwritter'],
        quote: ``,
        bio: `Zoya Akhtar (born 14 October 1972) is an Indian film director and screenwriter who works in Hindi cinema. Born to Javed Akhtar and Honey Irani, she completed a diploma in filmmaking from NYU and assisted directors Mira Nair, Tony Gerber and Dev Benegal, before becoming an independent writer and director. She is the recipient of several accolades, including four Filmfare Awards.[1] Akhtar, along with Reema Kagti, founded Tiger Baby Films, a film and web studio in October 2015.

        She made her directorial debut with the drama Luck by Chance (2009), and achieved her breakthrough with the ensemble buddy road comedy-drama Zindagi Na Milegi Dobara (2011), winning the Filmfare Award for Best Debut Director for the former, and the Filmfare Award for Best Director for the latter. She followed it up with co-writing the psychological crime thriller Talaash: The Answer Lies Within (2012) alongside Reema Kagti, and directing Sheila Ki Jawaani, a segment of the anthology film Bombay Talkies (2013).
        
        Akhtar then went on to direct the ensemble family comedy-drama Dil Dhadakne Do (2015), segments in the anthology films Lust Stories (2018) and Ghost Stories (2020), and the musical drama Gully Boy (2019), winning her second Filmfare Award for Best Director for the latter, thus becoming the only female director to have won the award twice. She ventured into the OTT space with the romantic comedy-drama series Made in Heaven (2019–present) and the crime thriller series Dahaad (2023–present). In 2023, she helmed the live-action feature film adaptation of The Archies for Netflix.
        
        She is also a board member of Mumbai Academy of the Moving Image (MAMI).`,
        socialLinks: {
            website: 'https://en.wikipedia.org/wiki/Zoya_Akhtar#'
        },
        otherCastAndCrews: [
            {
                castName: 'Reema Kagti',
                image: '',
                roles: ['Producer'],
                bio: ``
            },
            {
                castName: 'Sharad Devarajan',
                image: '',
                roles: ['Producer'],
                bio: ``
            },
            {
                castName: 'Jon Goldwater',
                image: '',
                roles: ['Producer'],
                bio: ``
            },
            {
                castName: 'Agastya Nanda',
                image: '',
                roles: ['Lead Actor'],
                bio: ``
            },
            {
                castName: 'Khushi Kapoor',
                image: '',
                roles: ['Lead Actor'],
                bio: ``
            },
            {
                castName: 'Suhana Khan',
                image: '',
                roles: ['Lead Actor'],
                bio: ``
            },
            {
                castName: 'Vedang Raina',
                image: '',
                roles: ['Lead Actor'],
                bio: ``
            },
            {
                castName: 'Mihir Ahuja',
                image: '',
                roles: ['Lead Actor'],
                bio: ``
            },
            {
                castName: 'Aditi "Dot" Saigal',
                image: '',
                roles: ['Lead Actor'],
                bio: ``
            },
            {
                castName: 'Yuvraj Menda',
                image: '',
                roles: ['Lead Actor'],
                bio: ``
            },
            {
                castName: 'Mihir Ahuja',
                image: '',
                roles: ['Lead Actor'],
                bio: ``
            },
            {
                castName: 'Mihir Ahuja',
                image: '',
                roles: ['Lead Actor'],
                bio: ``
            },
            {
                castName: 'Suhas Ahuja',
                image: '',
                roles: ['Supporting Actor'],
                bio: ``
            },

        ]
    },
    {
        filmName: "Daal Roti",
        castName: "Julee Jasmin",
        image: DaalRotiDirector,
        roles: ['Director'],
        quote: `"Without permission of God nothing happens in this universe...

        First Debut Director Feature film "DAAL ROTI"`,
        bio: `DEBUT DIRECTOR FEATURE FILM
        FORMER ASSOCIATE DIRECTOR IN LOTS OF HINDI MOVIE LIKE: NH8, BAALAA, YE FAASLEY, STARNGER BY THE HILL ETC.
        GOT THE AWARDS IN 16JIFF(16 JAIPUR INTERNATIONA FILM FESTIVAL)
        IFFA_OFFICIAL SELECTION( RESULT NOT OUT NOW)
        12TH ICFF MUMBAI _EXECELENCE AND APERACIATION AWARD`,
        socialLinks: {
            facebook: 'https://www.facebook.com/julee.jasmin?mibextid=ZbWKwL',
            instagram: 'https://www.instagram.com/juleejasmin?igsh=N3Y5NnZid294OTJo'
        },
        otherCastAndCrews: [
            {
                castName: 'SALIM AKHTAR',
                image: '',
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'DIWAKAR PRAVEEN',
                image: '',
                roles: ['Writer'],
                bio: ``,
            },
            {
                castName: 'DISHANT GULLIYA',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'RUPALI THAPA',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'KAUSTUBH DABRAL',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "Dream.",
        castName: "Shane Daniel Van Erp",
        image: DreamDirector,
        roles: ['Director', 'Producer', 'Writer', 'Key Cast'],
        quote: "",
        bio: ``,
        socialLinks: {
            facebook: 'https://www.facebook.com/profile.php?id=61558571468692&mibextid=LQQJ4d'
        }
    },
    {
        filmName: "Hercules Recycled 2.0",
        castName: "Don Moriarty",
        image: HR2Director,
        roles: ['Director', 'Writer', 'Producer', 'Key Cast'],
        quote: "",
        bio: ``,
        socialLinks: {
            youtube: 'https://youtu.be/yhFkRiPJrkM',
            website: 'http://herculesrecycled2.com',
            twitter: 'https://twitter.com/HercRecycled2',
            facebook: 'https://www.facebook.com/HerculesRecycled2'
        },
        otherCastAndCrews: [
            {
                castName: 'Greg Alt',
                image: HR2Director,
                roles: ['Director', 'Writer', 'Key Cast'],
                bio: ``,
            },
            {
                castName: 'David Trottier',
                image: HR2Director,
                roles: ['Writer'],
                bio: ``,
            },
            {
                castName: 'Steve Reeves',
                image: "",
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Sylva Koscina',
                image: "",
                roles: ['Key Cast'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "IRaH",
        castName: "Sam Bhattacharjee",
        image: IRaHDirector,
        roles: ['Director', 'Writer', 'Producer'],
        quote: "IRaH is a special project with meticulous work towards VFX. It is the near future.",
        bio: `Sam is the Creative Director of Do It Creative, a boutique production & post production house with studios in London and India which specialises in visual effects for feature films. Having worked at a high level within the film and TV industry for over 17 years, Sam has been involved with some of the biggest international Box Office hits of recent years.
        With a solid mastery of production and post-production, Sam’s approach to each project is utterly bespoke. He is respected for his vast industry knowledge and flexible approach. However it’s his high quality delivery of second line experimental production and his uniquely intuitive post-production methodologies, proven to aid films and long forms, that sets him apart from his competitors.
        Sam works in tandem with clients to ensure their vision and story is communicated effectively through creative ideas, skills, and the application of the right tools. This is what has earned Do It Creative its reputation for consistently brilliant results. Every project gets the same all-star-treatment, regardless of the size or value of the project and no screen moment is ever wasted. He then applies his theories with economies of scale in order to deliver maximum quality output.
        “Visualising is the start of everything; from the very first conversation with the client, it starts to play out in my head.”
        When it comes to generating ideas (in production and post-production) for Do It Creative's clients, Sam is the beginning and the successful end of every project.`,
        socialLinks: {
            youtube: 'https://www.youtube.com/watch?v=DfC9vGn4yX8',
            website: 'https://www.imdb.com/title/tt13675862/'
        },
        otherCastAndCrews: [
            {
                castName: 'Sara Bodinar',
                image: '',
                roles: ['Writer'],
                bio: ``,
            },
            {
                castName: 'Luke Hetherington',
                image: '',
                roles: ['Writer'],
                bio: ``,
            },
            {
                castName: 'Claudiu-Cristian Prisecaru',
                image: '',
                roles: ['Writer'],
                bio: ``,
            },
            {
                castName: 'Sudhanshu Dube',
                image: '',
                roles: ['Writer'],
                bio: ``,
            },
            {
                castName: 'Mitra Bhattacharya',
                image: '',
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'Rohit Bose Roy',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Rajesh Sharma',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Ameet Chana',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Karishma Kotak',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Fagun Thakrar',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "Meantime",
        castName: "Alicia Knight",
        image: MeantimeDirector,
        roles: ['Director', 'Writer', 'Producer'],
        quote: "",
        bio: `Alicia Knight is a nineteen year old filmmaker from Vietnam and Australia. She has been living in London and Geneva for the past four years, pursuing a degree in English Literature at UCL, London.`,
        socialLinks: {
            youtube: 'https://www.youtube.com/@aliciamai'
        },
        otherCastAndCrews: [
            {
                castName: 'Olivia Dale',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Mert Ayik',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Alicia Knight',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Ziggy Kermanshahchi',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Sanna Lileng',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Edouard Savalle',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "PJ",
        castName: "Patrick Gamble",
        image: "",
        roles: ['Director', 'Writer', 'Producer'],
        quote: "",
        bio: ``,
        socialLinks: {},
        otherCastAndCrews: [
            {
                castName: 'Ricky Gamble',
                image: '',
                roles: ['Director', 'Key Cast'],
                bio: ``,
            },
            {
                castName: 'Tess Bezzina',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Pina Gamble',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Patrick Kevin Gamble',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "Taarikh",
        castName: "Himjyoti Talukdar",
        image: TaarikhDirector,
        roles: ['Director', 'Writer'],
        quote: `"In "Taarikh," I explore the profound impact of grief and the enduring struggle of a man trapped in the past, unable to move forward. Through Durlov Dutta's poignant journey and Anuran Hazarika's compassionate intervention, the film delves into themes of loss, healing, and the human spirit's resilience. My goal is to evoke empathy and understanding, shedding light on the silent battles many face, and ultimately, to celebrate the power of connection in restoring hope and meaning to life." - Himjyoti Talukdar, Director, Taarikh`,
        bio: `Himjyoti Talukdar is an independent filmmaker and scriptwriter from Assam who has made significant strides in the Indian film industry. His directorial debut, "Calendar," released in 2018, garnered widespread acclaim for its heartfelt storytelling and compelling direction. Talukdar also contributed as a co-producer and scriptwriter for the Assamese feature film "Marksheet," further showcasing his versatility and talent in the cinematic arts. Looking ahead, he has two exciting projects on the horizon: "Taarikh," set to release in 2024, and a highly anticipated film adaptation of a novel by the esteemed Assamese author Dr. Bhabendra Nath Saikia, expected to hit the screens in 2025.`,
        socialLinks: {
            website: 'https://trendingnowmedia.com/taarikh'
        },
        otherCastAndCrews: [
            {
                castName: 'Arundhati Sarmah Baruah',
                image: '',
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'Muktismaan Hazarika',
                image: '',
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'Arun Nath',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Boloram Das',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "The Note",
        castName: "Chris Beadnell",
        image: TheNoteDirector,
        roles: ['Director', 'Writer', 'Producer'],
        quote: `THE NOTE:
        Our initial plan was simple: create a film, capture some footage, and hone our editing skills. But fate had other ideas. Through a series of serendipitous ads and leads, our small endeavor transformed into a full-fledged production. Suddenly, we found ourselves at the helm of a remarkable project—one that would connect people from different backgrounds, weaving their lives together all for this mysterious little note.
        
        At the outset we envisaged a cast and crew of about 8 or 9. We stopped counting at 25 ! A constellation of talent, passion, and dedication. Among them are acclaimed actors and those new to the art. A brilliant Director of Photography fresh from shooting ‘Mortal Kombat II,’ Sound producers, Lighting Specialists, Hair and Makeup Artists, and amazing Production Assistants and helpers. What’s most astonishing? They’ve all rallied behind our vision, working tirelessly for the love of storytelling.`,
        bio: `Chris Beadnell is a passionate filmmaker who discovered his love for scriptwriting at the tender age of 48. Over the years, he has honed his craft, earning numerous international awards for his screenplays. In 2023, Chris saw his first film produced, an experience that ignited his desire to move deeper into the world of filmmaking, to produce and to direct.

        Driven by this newfound passion, Chris pursued some formal studies and engaged in extensive self-research. He wrote, and then successfully brought together a cast and crew of 27 to co-produce and direct his debut film, The Note. Now, as he becomes more integrated into the industry, Chris continues to seek opportunities to write, produce, direct, and collaborate with other creatives in the film world.`,
        socialLinks: {
            website: 'https://the-note.my.canva.site/'
        },
        otherCastAndCrews: [
            {
                castName: 'Simon Mitchell',
                image: '',
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'Isaac Burton',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Davina May Marr',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Karis Merie',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Izzy Rowe',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Leon Stepien',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Joshua Roberts',
                image: '',
                roles: ['Cinematographer'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "Undercover Guptochor গুপ্তচর",
        castName: "Rahul Hasan",
        image: UndercoverDirector,
        roles: ['Director', 'Writer', 'Key Cast'],
        quote: `Commitment, honesty, hard work, dedication and confidence are the keys to success. Learning process has no ending, learning each time from my mistakes and continue to improve.`,
        bio: `Rahul Hasan was born in Dhaka in 20 December 1980, lives in Sydney since 2004. He is a singer, writer, actor and film maker. Undercover (গুপ্তচর) is his first web film about to release in 2024.`,
        socialLinks: {
        },
        otherCastAndCrews: [
            {
                castName: 'Jayed Julhash',
                image: '',
                roles: ['Writer'],
                bio: ``,
            },
            {
                castName: 'Farzana Hasan',
                image: '',
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'Nabila Islam',
                image: Undercover_Nabila,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Mahmudul Mithu',
                image: Undercover_Mahmudul,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Iqbal Hossain',
                image: Undercover_I,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Reshma Ahmed',
                image: Undercover_R,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Nor A Alam Nayon',
                image: Undercover_Nor,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Hannan Shelly',
                image: Undercover_H,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Shoishob Amiri',
                image: Undercover_S,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Mahidul Islam',
                image: Undercover_Mahidul,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Moshiur Rahman',
                image: '',
                roles: ['Cinematographer'],
                bio: ``,
            },
            {
                castName: 'Tonmoy Mohammad',
                image: '',
                roles: ['Editor'],
                bio: ``,
            },
            {
                castName: 'Apple Mahmud Emil',
                image: '',
                roles: ['Music Composer'],
                bio: ``,
            },
            {
                castName: 'Master Edit and Music: Rahul Hasan',
                image: '',
                roles: ['Editor', 'Music Composer'],
                bio: ``,
            },
        ] 
    },
    {
        filmName: "WHITE PANJAB",
        castName: "GABBAR SANGRUR",
        image: WhitePanjabDirector,
        roles: ['Director', 'Writer'],
        quote: `N/A`,
        bio: `Gabbar Sangrur, an alumnus of Panjab University,Chandigarh is a well known multi-skilled personality of Punjabi Film Industry.He grabbed his name in direction & production since one decade, also blessed with the talent of writing lyrics and film-stories. Gabbar was catapulated to fame with the release of punjabi web-series "" The Gangland In Motherland"" with each of episode clocking views in millions making a significant impact on regional cinema.
        Gabbar Sangrur’s movies and scripts often explores the deep societal issues reflecting the cultural and social dynamics of punjab.`,
        socialLinks: {
            instagram: `https://www.instagram.com/whitepanjab/`
        },
        otherCastAndCrews: [
            {
                castName: 'THE THEATRE ARMY FILMS',
                image: '',
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'KARTAR CHEEMA',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'KAKA JI',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'DAKSSH AJIT SINGH',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'RABBI KANDHOLA',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'INDER JEET',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'SAMUEL JOHN',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "Gingersnap Christmas",
        castName: "Julia Jay PIERREPONT III",
        image: GingersnapChristmasDirector,
        roles: ['Director', 'Writer', 'Producer'],
        quote: `Supporting diversity and expanding the voices of women, People of Color, Seniors and the LGBTQ community has been my focus for my entire career, when I pick my stories or hire my cast & crews. Then, after Covid, when so many people were having trouble coping with the loss of family and friends, I decided to take the lightest genre - a Christmas movie - and use it as a healing opportunity to help ordinary people deal with and recover from losses in their own lives during the “happiest of holidays.”`,
        bio: `Julia Jay Pierrepont III is an award-winning Hollywood writer/director/producer and the founder/CEO of Pierrepont Productions. She was voted “Best New Director in America” by Academy Award-winning judges, Francis Ford Coppola, Ron Howard, and Meryl Streep; she won the Los Angeles Film Festival Production Prize and “Best Director” and “Best Picture” at the NY Independent Film Festival. Her screenplays have been selected as Finalists in the prestigious Sundance Film Festival Screenplay Competition and New York’s Independent Feature Film Market (IFFM) “Top Ten Scripts” winners and won “Best Screenplay” at the Los Angeles WinFemme Film Festival. She is currently producing Tenor By Night with Arthur Sarkissian, to be directed by Rob Minkoff.`,
        socialLinks: {
            website: 'http://www.pierrepontproductions.com',
            facebook: 'https://www.facebook.com/juliapierrepontIII/'
        },
        otherCastAndCrews: [
            {
                castName: 'Evelyn Xu',
                image: '',
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'Desiree Ross',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Jake Lockett',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Cici Lau',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Erika Bowman',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Reka Lukacs',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "Abah and His band",
        castName: "Humberto Avelar",
        image: AbahAndHisBandDirector,
        roles: ['Director'],
        quote: `Aba and his band is a 3D animation mixed with 2D. The objective was to find its own visual identity and escape the more common language of 3D films, where everything looks very similar to reality, creating scenes that look like moving illustrations.`,
        bio: `Director of the three seasons of the animated series “Yellow Woodpecker Ranch”, broadcasted by Cartoon Network Brazil and Latin America, Tooncast, Boomerang and Globo channels. He is the director of the series “Ripilica World”, shown by the Discovery Kids channel and co-director of the series “Pilar's Diary” for the Nat Geo Kids channel. He has worked as a professor of comics and animation at UFRJ and received a special mention from UNICEF for the series “I Swear I Saw It”.`,
        socialLinks: {
            website: `http://www.abaesuabanda.com.br`
        },
        otherCastAndCrews: [
            {
                castName: 'Sylvio Gonçalves',
                image: '',
                roles: ['Writer'],
                bio: ``,
            },
            {
                castName: 'Silvia Fraiha',
                image: '',
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'Filipe Bragança',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Carol Valença',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Robson Nunes',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Zezé Motta',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Rafael Infante',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "Darwin the dreamer",
        castName: "Don Dixon",
        image: DarwinDirector,
        roles: ['Director', 'Writer'],
        quote: `Leave your mark.`,
        bio: `Don Dixon, a talented visual artist, wears many creative hats. With over 20 years of experience in art, design, and animation, he has left his mark on various projects, capturing the imagination of audiences worldwide.

        Born in North Carolina in 1978, Don Dixon grew up in a household where a 7-foot robot looked after him and his sisters. His father, a creator of robots for companies like Atari and Worlds of Wonder, eventually moved the family to California. This early exposure to technology and creativity shaped Don’s artistic journey.
        
        After majoring in Visual Arts at the Interlochen Arts Academy, Don continued his education at the Savannah College of Art and Design. He later completed his studies at the College for Creative Studies in Detroit, Michigan, earning a BFA in Animation and Digital Media. His first job was at WMS Gaming in Chicago, where he honed his skills in both 2D and 3D animation.
        
        Don’s passion for art and animation has only grown stronger over the years. He loves to laugh and collaborates with teams to create exciting new visions. His impressive portfolio includes work on feature films such as “The Book of Life,” “Free Birds,” “Rock Dog,” “Sherlock Gnomes,” “Ugly Dolls,” and “SCOOB!”. Additionally, he has contributed to television and video game animation and even illustrated for Rolling Stone Magazine.
        
        As the founder of Scrib Creative, Don Dixon continues to explore the fun side of art, emphasizing freedom of line, energy, and imagination. His goal is to ignite the flame of creativity in people and businesses. Whether through his captivating illustrations, animations, or speaking engagements, Don brings a unique blend of fine arts, writing, design, and animation to the creative world.
        
        If you’re looking for a visual artist who can breathe life into your ideas, Don Dixon is the one to watch. His work transcends boundaries, capturing the essence of imagination and storytelling.`,
        socialLinks: {
        },
        otherCastAndCrews: [
            {
                castName: 'Peter Baker',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Nela Ruiz',
                image: '',
                roles: ['Music Composer'],
                bio: ``,
            },
        ] 
    },
    {
        filmName: "In Half",
        castName: "Jorge Morais Valle",
        image: InHalfDirector,
        roles: ['Director', 'Writer', 'Producer', 'Animation', 'Characters Design', 'Editor', 'Visual Effects', 'Production Design', 'Cinematography'],
        quote: `I believe that animation has the power to transcend boundaries and connect people on a deeper level. Through my work, I aim to explore complex themes and emotions that resonate with viewers of all ages. I am committed to bringing diverse voices and perspectives to the forefront, and using the medium of animation to spark conversation and foster empathy.
        I believe that animation is a powerful tool for storytelling, and I strive to push the boundaries of what is possible in the medium. I am constantly seeking new ways to innovate and challenge myself creatively, in order to create work that is both visually stunning and emotionally resonant. My goal is to create animation that not only entertains, but also leaves a lasting impact on its audience, challenging them to think critically and feel deeply.
        I am dedicated to creating stories that are inclusive, thoughtful, and thought-provoking. I strive to create characters that are diverse, complex, and authentically represented. I believe that by centering the stories of underrepresented communities, we can create a more inclusive and empathetic world.
        Above all, I aspire to create animation that is meaningful, inspiring, and memorable. I am committed to using my platform as a filmmaker to shine a light on important and often overlooked stories, and to create work that has a lasting impact. Through my animation projects, I hope to inspire others to think, feel, and connect with each other in a deeper and more meaningful way.`,
        bio: `Jorge Morais Valle is an independent filmmaker and artist from Vigo (Spain). From a very young age he has been linked to some of the main artistic disciplines such as drawing, painting and sculpture, the latter being the one he has developed and highlighted most naturally at a very early age.
        He started very young in the world of graffiti and mural painting, where he soon found a very personal form of expression that attracted the attention of commercial brands to make artistic and advertising interventions. He quickly found the opportunity to extend his interests beyond the walls to the world of decoration and stage design in theatres, having the opportunity to lead work teams both in Spain and Portugal, even when he had not yet finished his secondary school studies.
         He combined his career in Fine Arts exploring different professional areas with the world of scenography, painting and illustration both in Galicia and Portugal, which would lead him to participate in different projects, painting exhibitions and advertising campaigns, leading a young group of artists, New Order Productions, which gave him great artistic knowledge about plasticity and leadership, qualities that gave him the confidence to enter the world of animation after graduating from the Faculty of Fine Arts.
        In 2010 he released his first animated short film, The painter of skies, as the final project of his Master's degree in animation, achieving great national and international recognition, being a project that was selected in more than 300 festivals and receiving 125 awards worldwide. After this experience he began a cycle of trips to different studios and production companies in Portugal, England, Italy and finally Germany, jumping from project to project and where he had the opportunity to deepen his knowledge in different areas of production, especially in production design, modelling and kinematics.`,
        socialLinks: {
            website: 'https://inhalf.net/',
            twitter: 'https://twitter.com/Beak_Art',
            instagram: 'https://instagram.com/beakart_j.morais'
        },
        otherCastAndCrews: [
            {
                castName: 'Silvia Pazos',
                image: '',
                roles: ['Writer'],
                bio: ``,
            },
            {
                castName: 'Cuco Fernández',
                image: '',
                roles: ['Writer'],
                bio: ``,
            },
            {
                castName: 'Jake Walters',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Jared Peterson',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Daniel Ledo',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Gábor Bakonyi',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'James Gillies',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Jose Lorenzo Valido',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Juan Manuel Cameán',
                image: '',
                roles: ['Music Composer'],
                bio: ``,
            },
            {
                castName: 'Priscila Gutiérrez',
                image: '',
                roles: ['Executive producer'],
                bio: ``,
            },
        ] 
    },
    {
        filmName: "Quentin Blake's Jack & Nancy",
        castName: "Massimo Fenati",
        image: QBJNDirector,
        roles: ['Director', 'Writer'],
        quote: ``,
        bio: ``,
        socialLinks: {
        },
        otherCastAndCrews: [
            {
                castName: 'Gerrit Bekers',
                image: '',
                roles: ['Director'],
                bio: ``,
            },
            {
                castName: 'Mark Evans',
                image: '',
                roles: ['Writer'],
                bio: ``,
            },
            {
                castName: 'Tess Cuming',
                image: '',
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'Peter Decraene',
                image: '',
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'Adrian Lester',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Huw Huckstep',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Marlon Day',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Stefano Cabrera',
                image: '',
                roles: ['Composer'],
                bio: ``,
            },
        ] 
    },
    {
        filmName: "Tweenbot",
        castName: "Hannah Samantha Simicic",
        image: TweenbotDirector,
        roles: ['Director', 'Writer', 'Producer'],
        quote: `N/A`,
        bio: `About Hannah
        From the moment she could mold a lump of playdough, Hannah was an artist in the making. Her creations were everywhere—underfoot, on the shelves, and even in the fridge! After stepping on one too many of her intricate figurines, her dad introduced her to Blender, and the rest, as they say, is history. She dove headfirst into the world of 3D animation, teaching herself every nook and cranny of the software.
        To date, she has devoured over 70 courses on Udemy, mastering everything from Blender and After Effects to drawing, camera operation, cinematography, directing, and even music composition (though she prefers to leave the composing to her dad!).
        Hannah has already completed three films and is hard at work on her fourth. She’s currently taking a year off to figure out her next steps, but all signs point towards a future in filmmaking. Her passion for science, sparked by channels like Kurzgesagt – In a Nutshell, feeds into her storytelling, infusing her films with a unique blend of creativity and curiosity.
        Despite having her own YouTube channel, she remains a bit shy about sharing her work. But she’s brimming with stories she’s eager to tell, aiming to make the world a slightly better place with each rendering she creates.
        Hannah also has a passion for traveling and always loves being on the move to see and experience new places. Each new destination seeds ideas for her future projects and fuels her creativity.
        Skills and Expertise
        Primarily working with Blender, Hannah is also proficient in ZBrush, After Effects, and Unreal Engine. Her post-production skills shine through in her color correction and video editing, honed with DaVinci Resolve. She’s dabbled in audio editing and composing using Audacity, GarageBand, and FL Studio. Her talents extend to cinematography, applying the same principles in both traditional camera work and 3D software environments.
        Inspiration and Creative Journey
        From a young age, borrowing her dad's camera, Hannah has been crafting short films. While her earliest works remain under wraps, these formative experiences have been instrumental in her development as a filmmaker and designer.
        Nature is a constant source of inspiration, with birds serenading her every morning and her beloved pets—cats and dogs—adding a touch of joy and humanity to her life. Travel sparks her creativity, and each new destination seeds ideas for future projects. Her voracious reading habit further fuels her imagination.
        Most importantly, her parents are her bedrock. Her dad is her rock, her guiding light, and her sounding board; their epic discussions have shaped her artistic journey. Her mom is her moral compass, her biggest cheerleader, and her shoulder to cry on. None of her accomplishments would have been possible without their unwavering support and inspiration.`,
        socialLinks: {
            website: 'https://hannie.art/'
        },
        otherCastAndCrews: [
            {
                castName: 'Jack Simmz',
                image: '',
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'Phil Baker',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Annette Lawless',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Joe Borowsky',
                image: '',
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Jack Simmz',
                image: '',
                roles: ['Music Composer'],
                bio: ``,
            },
        ] 
    },
    {
        filmName: "Back To Reality",
        castName: "Alison Maree Wheeler",
        image: BackToRealityDirector,
        roles: ['Director', 'Writer', 'Producer'],
        quote: `The all pervasive view that reality TV contestants are fame whores who 'get what they deserve' because they should have known what they were getting themselves into, is the main premise challenged by this film. My aim is that the viewer comes away with a new attitude of empathy and understanding that these are real people like you and I and as one of the contributors Kath says in the film 'we did nothing wrong but apply for a reality TV show!'`,
        bio: `Alison's career has spanned feature film, television, theatre and events. Having spent the last decade working in reality TV she is now working on her own projects, including factual video content and scriptwriting.`,
        socialLinks: {
        },
        otherCastAndCrews: [
            {
                castName: 'Scott Ferguson',
                image: '',
                roles: ['Producer', 'Editor'],
                bio: ``,
            },
            {
                castName: 'Marco Anastasio',
                image: '',
                roles: ['Editor', 'VFX'],
                bio: ``,
            },
            {
                castName: 'Rob Davies',
                image: '',
                roles: ['Cinematographer'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "Chernobyl - A Bomb That Keeps Ticking (2024)",
        castName: "Allen Dobrovolsky",
        image: CABTKTDirector,
        roles: ['Director', 'Producer'],
        quote: `"Chernobyl - A Bomb That Keeps Ticking" is not just a documentary; it is a profound journey into one of the most catastrophic events in human history and its enduring aftermath. As someone who was on the ground in the wake of the 1986 explosion, I have dedicated my life to uncovering the truth behind the lingering impacts of Chernobyl.

        Three decades after I first set foot in the devastated zone, the scars of that day remain as stark as ever. This documentary is the culmination of years of meticulous research and firsthand experiences. It aims to shed light on the ongoing environmental contamination and the grave health implications for millions of people. Through archival footage, personal interviews, and on-site investigations, the film unveils the disturbing reality of a disaster that continues to affect lives long after the initial explosion.
        
        The recent invasion of Ukraine has added a new layer of urgency to this story. The capture of the Chernobyl Nuclear Power Plant by Russian forces and the subsequent disappearance of 170 staff members underline the site's precarious position as a geopolitical pawn and a potential doomsday weapon. The reckless actions of the occupying troops, who dug trenches in highly contaminated Exclusion Zone, highlight the perpetual dangers that Chernobyl still poses.
        
        This documentary is a call to action. It is a reminder that the consequences of Chernobyl are far from over and that we must remain vigilant in our efforts to mitigate its impacts. My hope is that "Chernobyl - A Bomb That Keeps Ticking" will not only educate but also inspire global audiences to advocate for stronger environmental protections and accountability.
        
        As a filmmaker and a scientist, I am committed to telling stories that matter. This film is a testament to the resilience of those affected by the disaster and a warning of the enduring legacy of nuclear contamination. I invite you to join me on this journey and to help spread the message that Chernobyl's story is still unfolding and demands our attention.
        
        Thank you for considering "Chernobyl - A Bomb That Keeps Ticking" for your festival. It is a story that the world needs to hear.
        
        Dr A. Dobrovolsky
        Director, "Chernobyl - A Bomb That Keeps Ticking"`,
        bio: `Dr Allen Dobrovolsky is a world-renowned contamination expert and the director of the multi-award-winning documentary “Murder on the Reef” (2018). His career has been deeply intertwined with the Chernobyl disaster and its aftermath. Shortly after the catastrophic explosion at Chernobyl in 1986, Allen was sent to assess the fallout's effects. This pivotal experience set the course for his life's work, focusing on understanding and mitigating the long-term environmental and health impacts of radionuclide contamination.
        In recent years, Allen's expertise has extended to addressing the geopolitical ramifications of nuclear energy sites in conflict zones. The occupation of the Chernobyl Nuclear Power Plant and the Zaporizhzhia Nuclear Power Plant by Russian forces during the Ukraine war brought renewed urgency to his mission. The capture and subsequent disappearance of 170 staff members at Chernobyl, along with the reckless actions of the occupying troops, underscore the ongoing dangers posed by these sites.
        Allen's latest documentary, "Chernobyl - A Bomb That Keeps Ticking," is a powerful exploration of these issues. The film combines archival footage, personal interviews, and on-site investigations to reveal the harrowing story of environmental contamination, government cover-ups, and the ongoing threat to millions of people. Released in late April 2024, the documentary has already begun to garner attention for its compelling narrative and urgent message.
        In addition to his work on Chernobyl, Allen has conducted extensive scientific studies across Europe, assessing the environmental and health impacts of anthropogenic pollution. He founded A.D. Envirotech Australia Pty Ltd in 1997, which later evolved into ADE Consulting Group Pty Ltd, a leading environmental consultancy in Australia. His film production company, AESA Films, has produced acclaimed documentaries, including "Murder on the Reef," which highlights environmental challenges and has received 16 Best Documentary Film awards.
        Allen's commitment to environmental protection extends beyond filmmaking. In October 2023, he was invited to speak at the high-level International Conference ""United for Justice. United for Nature"" in Ukraine, where he emphasized the severe ecological consequences of the Russian war on Ukraine's ecosystem. His presentation highlighted the potential contaminants of concern along the frontline and downstream of the Kakhovka dam.
        Dr Allen Dobrovolsky continues to lead efforts in environmental remediation and advocacy, leveraging his unique blend of scientific expertise and storytelling to address some of the most pressing environmental challenges of our time. His work on ""Chernobyl - A Bomb That Keeps Ticking"" is a testament to his dedication to uncovering the truth and inspiring global action.
        For more information, visit Allen Dobrovolsky on IMDb: https://www.imdb.com/name/nm9586974/`,
        socialLinks: {
            website: 'https://aesafilms.com/',
            twitter: 'https://x.com/ADobrovolsky3/status/1813869235627762049',
            facebook: 'https://www.facebook.com/profile.php?id=61555149633478',
            instagram: 'https://www.instagram.com/aesafilms/?hl=en'
        },
        otherCastAndCrews: [
            {
                castName: 'Paddy Payne',
                image: '',
                roles: ['Writer', 'Editor'],
                bio: ``,
            },
            {
                castName: 'Tim Payne',
                image: '',
                roles: ['Editor'],
                bio: ``,
            },
            {
                castName: 'Danil Shevchenko',
                image: '',
                roles: ['Cinematographer'],
                bio: ``,
            },
            {
                castName: 'Ben Sims',
                image: '',
                roles: ['Music Composer'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "Chhava",
        castName: "Sudeep Mehta",
        image: missingImage,
        roles: ['Producer'],
        quote: `N/A`,
        bio: `N/A`,
        socialLinks: {
            website: 'https://www.imdb.com/title/tt32117939/',
            instagram: 'https://instagram.com/chhava.official'
        },
        otherCastAndCrews: [
            {
                castName: 'Anurag Srijit',
                image: missingImage,
                roles: ['Director'],
                bio: ``,
            },
            {
                castName: 'Deep Kadam, Azzam Khan, Kshitij Jagdale',
                image: missingImage,
                roles: ['Director'],
                bio: ``,
            },
            {
                castName: 'Azzam Khan, Kshitij Jagdale',
                image: missingImage,
                roles: ['Director'],
                bio: ``,
            },
            {
                castName: 'Kshitij Jagdale',
                image: missingImage,
                roles: ['Director'],
                bio: ``,
            },
            {
                castName: 'Arman Shaikh',
                image: missingImage,
                roles: ['Editor'],
                bio: ``,
            },
        ] 
    },
    {
        filmName: "Colebrook",
        castName: "Matthew Shannon",
        image: ColebrookDirector,
        roles: ['Director'],
        quote: `N/A`,
        bio: `Matt is a member of the Australian Directors Guild (ADG), Australian Editors Guild (ASE) and Australian Cinematographers Society.
        With over twenty years experience, Matt has created numerous films that have been accepted into festivals such as the documentary Cosmosapiens – New York Independent Film Festival & Adelaide French Film Festival and The Missing Years – Palm Springs Film Festival. Throughout his career he has created numerous corporate videos with clients for live events, commercials and educational videos for such companys as Puratap, Jim’s Plumbing, Hancock Prospecting, Master Builders, BNI, Ministry Dance Company, SCL Schumann, Hyundai, The Australian Ballet Company, to name only a few.
        Matthew Shannon prides himself on creating videos for any purpose that tell compelling stories that make an impact on targeted audiences.`,
        socialLinks: {
        },
        otherCastAndCrews: [
            {
                castName: 'Peta Louise Shannon',
                image: missingImage,
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'JENNADENE Smith',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Sherrell Dyer',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Maragert Apma',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Raymond Finn',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Mandy Brown',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Moogy (Major) Sumner',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Kunyi June Anne McInerney',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'David Gregan',
                image: missingImage,
                roles: ['Cinematography'],
                bio: ``,
            },
            {
                castName: 'Matthew Shannon',
                image: missingImage,
                roles: ['Cinematography'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "Dreamweavers - Gidja Walker OAM",
        castName: "Heather Forbes McKeon, Yanni Dellaportas",
        image: DreamweaversDirector,
        roles: ['Director'],
        quote: `We were privileged to make this film about Gidja; known as a Mornington Peninsula icon for her work and knowledge as an ecologist and Mornington Peninsula indigenous advocate. Gidja combines her immense environmental knowledge alongside traditional owner’s relationships with her local environment. She works tirelessly for the protection of natural environments far and wide whilst balancing her work and voluntary commitments with her creativity as a painter. Her time in nature and creativity reflects her deeply spiritual self which is also expressed through her love of family, friends, and a great sense of fun. It was wonderful making this film about her!`,
        bio: `Heather Forbes-McKeon is a playwright, screenwriter, poet, short film director and producer and radio producer. Heather wrote, co-directed and produced the short film series ‘Dreamweavers’ (2021-2023). She wrote and produced the short film ‘The Shell’ in 2018. She also produced a series of ten radio plays for RPP FM over 2017 - 2018. Melbourne Writer’s Theatre have produced some of her works including: ‘Hope’ in 2017, ‘‘Desperate & Dirty at Twilight’ in 2019 and both 'Iso in Fountain Lakes' and ‘This Sheila’s Lockdown’ in 2020. Heather completed a Masters in Writing and Literature at Deakin University in 2014. Heather is the founder and convenor of Poets Corner; a Mornington Peninsula based group of performance-bards.

        Yanni Dellaportas
        is a professional visual storyteller who resides and works on the Mornington Peninsula, Victoria, Australia, as a well-established photographer and digital artist. Yanni began creating and directing short films in 2018. Yanni collaborated with Forbes-McKeon on the short film series ‘Dreamweavers’ (2021- 2023) as co-director, cinematographer, editor and music composer.
        Past credits include: ‘The Shell’ (2018), ‘Liquid’ (music video- 2018), Beautiful Storm (2023) and as co- director on ‘Dreamweaver – Jacquie Beddows’ (2022). He is currently collaborating as co-director on web-series of documentaries entitled ‘Dreamweavers’.
        Yanni’s award-winning photographs have been exhibited in fine art and photographic exhibitions across Australia. His work is widely recognised having been published in leading Australian newspapers, magazines, photo books and international novels.
        Since the 1990s his work as a photojournalist and innovative digital artist has kept Yanni’s work in the public consciousness. His photographs are immediately recognizable. From iconic landscapes to breathtaking thunderstorms, local wildlife or the people in his community, Yanni’s photographs are synonymous with quality and insight.`,
        socialLinks: {
            facebook: 'https://www.facebook.com/profile.php?id=61552219764765&mibextid=LQQJ4d',
            instagram: 'https://www.instagram.com/dreamweavers319/'
        },
        otherCastAndCrews: [
            {
                castName: 'Heather Forbes McKeon',
                image: missingImage,
                roles: ['Writer', 'Producer'],
                bio: `Heather Forbes-McKeon is a playwright, screenwriter, poet, short film director and producer and radio producer. Heather wrote, co-directed and produced the short film series ‘Dreamweavers’ (2021-2023). She wrote and produced the short film ‘The Shell’ in 2018. She also produced a series of ten radio plays for RPP FM over 2017 - 2018. Melbourne Writer’s Theatre have produced some of her works including: ‘Hope’ in 2017, ‘‘Desperate & Dirty at Twilight’ in 2019 and both 'Iso in Fountain Lakes' and ‘This Sheila’s Lockdown’ in 2020. Heather completed a Masters in Writing and Literature at Deakin University in 2014. Heather is the founder and convenor of Poets Corner; a Mornington Peninsula based group of performance-bards.`,
            },
            {
                castName: 'Gidja Walker',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Yanni Yanni',
                image: missingImage,
                roles: ['Cinematography', 'Editor'],
                bio: `Yanni Dellaportas
                is a professional visual storyteller who resides and works on the Mornington Peninsula, Victoria, Australia, as a well-established photographer and digital artist. Yanni began creating and directing short films in 2018. Yanni collaborated with Forbes-McKeon on the short film series ‘Dreamweavers’ (2021- 2023) as co-director, cinematographer, editor and music composer.
                Past credits include: ‘The Shell’ (2018), ‘Liquid’ (music video- 2018), Beautiful Storm (2023) and as co- director on ‘Dreamweaver – Jacquie Beddows’ (2022). He is currently collaborating as co-director on web-series of documentaries entitled ‘Dreamweavers’.
                Yanni’s award-winning photographs have been exhibited in fine art and photographic exhibitions across Australia. His work is widely recognised having been published in leading Australian newspapers, magazines, photo books and international novels.
                Since the 1990s his work as a photojournalist and innovative digital artist has kept Yanni’s work in the public consciousness. His photographs are immediately recognizable. From iconic landscapes to breathtaking thunderstorms, local wildlife or the people in his community, Yanni’s photographs are synonymous with quality and insight.`,
            },
            {
                castName: 'Yanni Dellaportas',
                image: missingImage,
                roles: ['Music Composer', 'Director'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "Flint Fatigue: A Poisoned City-and the Cover-Up in Plain Sight",
        castName: "Tommie Tyhefe",
        image: FlintFatigueDirector,
        roles: ['Director', 'Producer', 'Editor', 'Music Composer'],
        quote: ``,
        bio: ``,
        socialLinks: {
        },
        otherCastAndCrews: [
            {
                castName: 'Jordan Chariton',
                image: FlintFatigueDirector,
                roles: ['Writer', 'Producer', 'Key Cast'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "Freeland ~ A White Grass Story",
        castName: "Justin Harris",
        image: FreelandDirector,
        roles: ['Director', 'Writer', 'Producer'],
        quote: `'Freeland~A White Grass Story' was a two year project that grew throughout production. Our initial goal was a small film project that showcased the unique aspects of the resort. However, once we dove in and found the storylines, it was apparent to everyone that the story that needed to be told would take time and commitment, and that's exactly what we did. As a result, we are fulfilling our mission of asking the question "what is the future of White Grass" and seeing the resulting action taken to make an impact. When it comes to film, there's no greater success than watching people be called to action by our work.`,
        bio: `Justin Harris is a photographer, content creator, and filmmaker living deep in the mountains of West Virginia. He is an avid kayaker and skier and began his career in action sports photography. His exploration into video came during his tenure as the Media Director of a small West Virginia ski resort. After the success of The Red Creek Sessions, Justin chose to focus his film efforts on his favorite place on earth, White Grass Ski Area in West Virginia's Canaan Valley. White Grass is a globally loved destination, but its future is as delicate as the land in which it rests. Mountain River Media had one very specific mission with this film....to raise the question "what is the future of White Grass?"`,
        socialLinks: {
            website: 'Freeland ~ A White Grass Story',
            facebook: 'https://www.facebook.com/Mountainrivermedia2120/',
            instagram: 'https://www.instagram.com/harrisboofs/'
        },
        otherCastAndCrews: [
            {
                castName: 'Brandon Thompson',
                image: missingImage,
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'Will Fortune',
                image: missingImage,
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'Sue Haywood',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Chip Chase',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Jess Shimrock',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Stephen Strothers',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "Generations To Come",
        castName: "Eva Cecilia Iversen",
        image: missingImage,
        roles: ['Director', 'Writer'],
        quote: `N/A`,
        bio: `N/A`,
        socialLinks: {
        },
        otherCastAndCrews: [
            {
                castName: 'Ingrid Iversen',
                image: missingImage,
                roles: ['Director'],
                bio: ``,
            },
            {
                castName: 'Ingrid Tollefsen',
                image: missingImage,
                roles: ['Writer'],
                bio: ``,
            },
            {
                castName: 'Kristoffer Bredvei Wergeland',
                image: missingImage,
                roles: ['Writer', 'Producer'],
                bio: ``,
            },
            {
                castName: 'Håkon Iversen',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Martin Iversen',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Ellen Iversen',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Torunn Birgitte Iversen',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Rune Iversen',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Eva Cecilia Iversen',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "In The Trenches",
        castName: "Benjamin Scotford",
        image: InTheTrenchesDirector,
        roles: ['Director'],
        quote: `This documentary is intended to be a standalone story, enjoyable for a wide range of audiences even if they are unfamiliar with Before Dawn.
        I have aimed to capture the story similarly to how I experienced it firsthand. Witnessing a group of dedicated filmmakers working against a mountain of obstacles without knowing the final outcome.`,
        bio: `A Documentary Filmmaker with over 9 years of experience as a professional videographer. Benjamin has a passion for Behind the Scenes content and is always looking for new ways to approach the genre.`,
        socialLinks: {
        },
        otherCastAndCrews: [
            {
                castName: 'Sean Tinnion',
                image: missingImage,
                roles: ['Composer'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "Invisibles México",
        castName: "Rafael Franco Gomez",
        image: InvisiblesMéxicoDirector,
        roles: ['Director'],
        quote: `N/A`,
        bio: `Rafi is a composer and director originally from the highlands of Jalisco in Mexico. He began his career as a director with independent films like “El Caos” in 2017. He released his first film music album in 2022 and his most recent feature film is the documentary “Invisibles México” this 2024`,
        socialLinks: {
        },
        otherCastAndCrews: [
            {
                castName: 'Joel Valle',
                image: missingImage,
                roles: ['Writer'],
                bio: ``,
            },
            {
                castName: 'Rafael Franco',
                image: missingImage,
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'Miguel Sierralta',
                image: missingImage,
                roles: ['Producer'],
                bio: ``,
            },
            {
                castName: 'Rosa Elena González',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
        ] 
    },
    {
        filmName: "Joy of Art",
        castName: "Takaya Onishi",
        image: JoyOfArtDirector,
        roles: ['Director', 'Producer'],
        quote: `For this project, we tried to make the work an “experience” in which the viewer feels and discovers something from the images, with almost no explanatory tickers, narration, or other information. This is because the artists and children who appear in the film, including Tomoko Kasahara, and the scenery of Cambodia, contain everything. However, that does not mean that we did not make any changes at all. One of the things that made the work even better was the music: “Children's Song in the Future” by Felipe Nakamura incorporates the voices of Cambodian children singing over a melody reminiscent of Khmer culture. It evokes the culture, art, and traditions that were destroyed by the Pol Pot regime and lights the way for a new future.`,
        bio: `1987 Born in Kakogawa City, Hyogo Prefecture
        2006 Entered Faculty of Engineering, Okayama University
        2008 Formed the comedy duo “Link Up”
        2011 Graduated from the Faculty of Engineering, Okayama University
        2015 Completed short film “Mahoroba”
        2016 Completed short film “Iwamoto has one month left to live”
        2017 Completed feature film “Costume Master”
        2018 Founded STUDIO TONOLION as an individual founder
        2020 Appointed as the representative director of Filmmaker Support Organization
        2021 Establishment of joint venture STUDIO TONOLION
        2021 Completed the short film “Owl of Minerva -Requiem”
        2022 Completion of feature film “Owl of Minerva”
        2022 Appointed as the representative of Children's Film Club
        2023 Obtained qualification as a nursery teacher
        2024 Completed Documentary film “Joy of Art”
        2024 Awarded for NOMINATED Miyakojima Charity International Film Festival 2024`,
        socialLinks: {
            website: 'https://tonolion.jp'
        },
        otherCastAndCrews: [
            {
                castName: 'Tomoko Kasahara',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
        ] 
    },
    {
        filmName: "LA GRANDE SIGNORA",
        castName: "Valentina Faenza",
        image: LaGrandeSignoraDirector_V,
        roles: ['Director', 'Writer', 'Producer'],
        quote: ``,
        bio: `VALENTINA FAENZA Professione medico e artista plastica, da sempre appassionata di fotografia e sempre con la camera in mano fin da piccola per raccontare la realtà intorno a lei. Questa è la sua prima esperienza come documentarista. Dopo un lungo periodo in Spagna a Barcellona è rientrata nel 2019 in Italia quando ha saputo che dopo tantissimi anni l'impresa di famiglia si sarebbe spostata fuori da Palazzo Albergati, per lei luogo del cuore. L'esigenza di raccontare questa storia l'ha spinta ad decidere di autoprodursi completamente questo documentario ed iniziare le riprese in Dicembre 2018 fino all'ultimo giorno di ottobre 2019, data dell'uscita da Palazzo. Racconta questa storia in prima persona alternandosi come voce narrante, intervistando in prima persona i protagonisti principali e rimanendo più volte dietro alla cinepresa per poter raccontare attraverso il suo sguardo la quotidianità della vita a Palazzo fino alla parte finale del trasloco.`,
        socialLinks: {
        },
        otherCastAndCrews: [
            {
                castName: 'Manuel Rossi',
                image: LaGrandeSignoraDirector_M,
                roles: ['Director'],
                bio: `MANUEL ROSSI Di professione operatore video, ha partecipato in vari progetti audiovideo ma questo è il suo primo lungometraggio. Ha incontrato Valentina per caso quando un giorno lei è andata a restituire un microfono nello studio di produzione dove lui stava lavorando. Si è lasciato affascinare da questo progetto e ha inziato insieme ad altri colleghi ad affiancare Valentina durante le riprese. Posteriormente ha deciso di appoggiarla e di affiancarla per terminare il progetto portando avanti insieme a lei il montaggio che è terminato in ottobre 2023`,
            },
            {
                castName: 'Egle Conti',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Carlo Faenza',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Alessandro Faenza',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            },
            {
                castName: 'Valentina Faenza',
                image: missingImage,
                roles: ['Key Cast'],
                bio: ``,
            }
        ] 
    },
    {
        filmName: "Mountain Boy",
        castName: "Zainab Sheheen",
        image: missingImage,
        roles: ['Director'],
        quote: ``,
        bio: ``,
        socialLinks: {
            website: 'https://x.com/desertrosefilms',
            facebook: 'https://www.facebook.com/mountainboymovie',
            instagram: 'https://www.instagram.com/mountainboymovie/'
        },
        otherCastAndCrews: [
            {
                castName: 'Nancy Paton, Raihana Al Haishim',
                image: missingImage,
                roles: ['Writer'],
                bio: ``,
            },
            {
                castName: 'Nancy Paton, Raihana Al Haishim',
                image: missingImage,
                roles: ['Writer'],
                bio: ``,
            },
        ] 
    },
    {
        filmName: "111",
        castName: "",
        image: missingImage,
        roles: [],
        quote: ``,
        bio: ``,
        socialLinks: {
        },
        otherCastAndCrews: [
            {
                castName: '',
                image: missingImage,
                roles: [],
                bio: ``,
            },
        ] 
    },
    {
        filmName: "Betelgeuse",
        castName: 'Sunder Konar',
        image: Betelgeuse_Director,
        roles: ['Director', 'Co Producer'],
        quote: `The film "BETELGEUSE" holds profound relevance. It explores the personal journey of Chitra, a young woman grappling with depression, shedding light on the challenges faced by individuals in our society.`,
        bio: `Sunder Konar is an accomplished Assistant Director in the Indian film industry, renowned for his contributions to blockbuster movies such as Kaabil (2017), Dream Girl (2019), Toofaan (2021), Radhe Shyam (2022), and Pippa 1971 India Pakistan war. He has also made a mark in the world of short films and music videos.
        An accomplished boxer and multilingual speaker, Sunder Konar's diverse talents promise to leave a lasting impact in the entertainment industry.`,
        socialLinks: {
            facebook: 'http://facebook.com/quentin',
            twitter: 'http://twitter.com/quentin'
        },
        otherCastAndCrews: [
            {
                castName: 'Ramkumar K',
                image: missingImage,
                roles: ['Producer'],
                bio: `Ram is a dynamic entrepreneur with 15 years of experience in environmental markets. As a founding member and director of sales at RE Connect Energy Solutions, he drives business expansion. Previously, he played a crucial role in setting up Gensol Consultants, now a renowned name. Aside from business, Ram is an avid basketball player and music enthusiast. He follows his passion for film-making and currently produces the short feature "Betalgeuse." Leading the utility business at RE Connect Energy Solutions, Ram's expertise fuels growth and profitability, garnering respect from peers and colleagues, establishing him as a successful film producer and industry leader.`,
            },
            {
                castName: 'Mayur Hardas',
                image: missingImage,
                roles: ['Producer', 'Cinematographer', 'Editor', 'Director Of Photography'],
                bio: `Mayur Hardas is an accomplished Indian movie editor with an impressive portfolio of work, including notable films like "Postcard," "Bioscope," "Mr. & Mrs. Sadachari," "Ranjan," and "Avatarachi Goshta." Hailing from Pune, he commenced his journey in the film industry through short films, eventually directing and producing over 50 of them. With eight years of experience in the Marathi film industry, Mayur independently thrived as an editor and cinematographer. In 2017, he showcased his creativity as a producer in the film "TTMM." Moreover, Mayur directed a Hindi film titled "Dark Light" in 2019. His versatility extends to web series, documentaries, and theater productions, having excelled in ad films and corporate films alongside his filmmaking pursuits. Passionate about exploring various facets of filmmaking, Mayur aspires to continue carving a successful path in the industry and leaves no stone unturned in showcasing his exceptional abilities.`,
            },
            {
                castName: 'Lokesh Kumar',
                image: missingImage,
                roles: ['Screenwriter'],
            },
            {
                castName: 'Gayatri Datar',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Gayatri Datar is a Marathi television actress . Gayatri is known for the serial Tula Pahete Re . Currently she has participated in Bigg Boss Marathi 3 .`,
            },
            {
                castName: 'Ankit Nayar',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Debut Actor`,
            },
            {
                castName: 'Chitrapama James',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Shiva Chopra',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Manu Malik',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Simran Chandwani',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Lalit Prashar',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Akshay Salve',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Akhil Nayar',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Yeshay phunchok',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Bhuvnesh Thakur',
                image: missingImage,
                roles: ['Actor'],
            },
            {
                castName: 'Akshay Salve',
                image: missingImage,
                roles: ['Editor'],
            },
            {
                castName: 'Arun Raj',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `Kollywood (Tamil) famous- Music Director of #Thadam | #Pizza3 | #Erumbu | #Byri`
            },
            {
                castName: 'Moinac Banerjee',
                image: missingImage,
                roles: ['Production Designer'],
                bio: `Debut`
            },
            // {
            //     name: 'Akhil Nayar',
            //     image: missingImage,
            //     roles: ['Executive Producer'],
            // },
            // {
            //     name: 'Prashant Johari',
            //     image: missingImage,
            //     roles: ['Lyrics'],
            // },
            // {
            //     name: 'Lokesh Singh',
            //     image: missingImage,
            //     roles: ['Writers'],
            // },
            // {
            //     name: 'Mayur Hardas',
            //     image: missingImage,
            //     roles: ['Writers'],
            // },
            // {
            //     name: 'Niha Hardas',
            //     image: missingImage,
            //     roles: ['Costume Designer'],
            // },
            // {
            //     name: 'Niranjana Naiker',
            //     image: missingImage,
            //     roles: ['Accounts Head'],
            // },
            // {
            //     name: 'Shachi Shah',
            //     image: missingImage,
            //     roles: ['1st AC'],
            // },
            // {
            //     name: 'Shubham Katariya',
            //     image: missingImage,
            //     roles: ['2nd AC & Drone Operator'],
            // },
            {
                castName: 'Jerry Mathew',
                image: missingImage,
                roles: ['Assistant Directors'],
            },
            {
                castName: 'Karthik Sethe',
                image: missingImage,
                roles: ['Assistant Directors'],
            },
            {
                castName: 'Gokul Konar',
                image: missingImage,
                roles: ['Assistant Directors'],
            },
            // {
            //     name: 'Urvashi Ajmera',
            //     image: missingImage,
            //     roles: ['Production Coordinator'],
            // },
            {
                castName: 'Sukhamrit Soin',
                image: missingImage,
                roles: ['Singer'],
            }
        ]
    },
    {
        filmName: "Celebration: A Bliss Parade",
        castName: 'Jonathan Gradiyan',
        image: CelebrationABlissParadeDirector,
        roles: ['Director'],
        quote: ``,
        bio: `Jonathan Gradiyan is a young film student that was born in December 2003 and raised in
        Yogyakarta, and now pursuing his passion by continuing his film study at the Jakarta Institute of Arts.
        Although he had done some small projects before enrolling into the Jakarta Institute of Arts. He made his
        first debut as a film director by directing his first proper short film “35 Mm”, by the end of his first semester.
        This film was shot in Yogyakarta, his hometown, and formed by a small scale of crew. Later then, he took
        on plenty projects as a Director of Photography too.`,
        socialLinks: {
            instagram: `https://www.instagram.com/selebrasikebahagiaan?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==
                        https://www.instagram.com/pallapapictures?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==`
        },
        otherCastAndCrews: [
            {
                castName: 'Redemptus Lintang',
                image: CelebrationABlissParadeProducer_R,
                roles: ['Producer'],
                bio: `Redemptus Lintang is a Law Student at Atma Jaya Yogyakarta University. He was born in August
                2004 in Yogyakarta and was raised in Tangerang for a while then went back to Yogyakarta for his studies.
                Although he went to a law school, he shared the same passion as Jonathan, as they have been friends since middle
                school.
                “35 Mm” a film directed by Jonathan Gradiyan was Lintang’s debut in a short movie as well as Jonathan, he
                produced the film in December 2022. Later, he produced “Menghasilkan Bayangan Paling Gelap” that is
                currently still on post. Then by the early of 2024 he produced “Celebration: A Bliss Parade”, a docufiction 
                movie directed by Jonathan Gradiyan.`,
            },
            {
                castName: 'Jonathan Gradiyan',
                image: missingImage,
                roles: ['Screenwriter'],
                bio: `Jonathan Gradiyan is a young film student that was born in December 2003 and raised in
                Yogyakarta, and now pursuing his passion by continuing his film study at the Jakarta Institute of Arts.
                Although he had done some small projects before enrolling into the Jakarta Institute of Arts. He made his
                first debut as a film director by directing his first proper short film “35 Mm”, by the end of his first semester.
                This film was shot in Yogyakarta, his hometown, and formed by a small scale of crew. Later then, he took
                on plenty projects as a Director of Photography too.`,
            },
            {
                castName: 'Josephine Dian',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Josephine Dian is a student at Atma Jaya University of communication major study.
                She’s a professional dancer that has been passionate at very young age. Although not only dancing,
                she has done a project with Viu as an actress during high school.`,
            },
            {
                castName: 'Luftia (Utiya) Yuna Putri',
                image: CelebrationABlissParadeCinematographer,
                roles: ['Cinematographer'],
                bio: `Lutfia Yuna Putri is a young film student from Padang, Indonesia, now studying
                in the Indonesia Institute of Arts Yogyakarta. She’s really passionate about photography and
                cinematography since before entering a film major college.`
            },
            {
                castName: 'Muhammad Arif Rahman',
                image: CelebrationABlissParadeEditor,
                roles: ['Editor'],
                bio: `Arif is a young film student at Jakarta Institute of Arts. During high school he used
                to join in a film club at school, there he begun learning the basics of editing until he tried
                making a short film with a couple of his friends. His interest in editing film grew afterwards
                because of this, he finds that editing in films took an important roles in filming. That is why he
                chose to continue his study at the Jakarta Institute of Arts, and he’s planning to take a major in
                editing.`
            },
            {
                castName: 'Muhhamad Fajar Ghifari',
                image: CelebrationABlissParadeMusic,
                roles: ['Music Composer'],
                bio: `Muhhamad Fajar Ghifari, or as people call him Fajar, is a young film student born
                on the 20th of November 2003 and originated from Bandung. Before attending film school, he       
                had already done some songs with his band. Besides music composing and sound designing he
                is also active as a film director, his work as a director was directing 2 short films, Kicep
                (2024) and Rubbish Boy (2024).`
            },
            {
                castName: 'Bikeska Sanara',
                image: CelebrationABlissParadeDesigner,
                roles: ['Production Designer'],
                bio: `Bikeska is a young film student currently studying in the Jakarta Institute of Arts,
                as a film student, Bikeska spent a lot of time in the art department for. He had plenty of
                experience as a set dresser as well as an assistant for an art director.`
            }
        ]
    },
    {
        filmName: "IDOL",
        castName: 'Suhas Pattathil',
        image: missingImage,
        roles: ['Director'],
        quote: `N/A`,
        bio: `A passionate actor-director-producer, wishing to create films that speak art and fun.`,
        socialLinks: {
            instagram: `https://www.instagram.com/suhaspattathil/`,
            facebook: `https://www.facebook.com/dmpicz`
        },
        otherCastAndCrews: [
            {
                castName: 'Devi Mohan',
                image: missingImage,
                roles: ['Producer'],
                bio: `A small production house that loves art.`,
            },
            {
                castName: 'Mohan Kumar for DM Picz',
                image: missingImage,
                roles: ['Producer'],
                bio: `A small production house that loves art.`,
            },
            {
                castName: 'Suhas Pattathil',
                image: missingImage,
                roles: ['Screenwriter'],
                bio: `A small production house that loves art.`,
            },
            {
                castName: 'Ishani Suhas',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: ``,
            },
            {
                castName: 'Ruth Anumodh',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: ``,
            },
            {
                castName: 'Rebecca Anumodh',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: ``,
            },
            {
                castName: 'Donna Richard',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: {instagram: `https://www.instagram.com/i_m_donna_richard/`},
            },
            {
                castName: 'Elizabeth Varkey',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: {instagram: `https://www.instagram.com/varkey.elizabeth/`},
            },
            {
                castName: 'Anumodh Paul',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: {instagram: `https://www.instagram.com/anumodupaul/`},
            },
            {
                castName: 'Ellen Coote',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: {instagram: `https://www.instagram.com/ellecoote/`},
            },
            {
                castName: 'Avinash Panicker',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: {instagram: `https://www.instagram.com/madmanshooting/`}
            },
            {
                castName: 'Sreejith Jayadevan',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: {instagram: `https://www.instagram.com/madmanshooting/`}
            },
            {
                castName: 'Vinayan M.J.',
                image: missingImage,
                roles: ['Editor'],
                bio: {instagram: `https://www.instagram.com/vinayan_mj/`}
            },
            {
                castName: 'Sidhartha Pradeep',
                image: missingImage,
                roles: ['Music Composer'],
                bio: {instagram: `https://www.instagram.com/sidhartha_pradeep/`}
            },
            {
                castName: 'Suhas Pattathil',
                image: missingImage,
                roles: ['Production Designer'],
                bio: {instagram: `https://www.instagram.com/sidhartha_pradeep/`}
            }
        ]
    },
    {
        filmName: "Clown",
        castName: 'Aarushi Chowdhury',
        image: missingImage,
        roles: ['Director'],
        quote: `N/A`,
        bio: `A passionate actor-director-producer, wishing to create films that speak art and fun.`,
        socialLinks: {
            instagram: `https://www.instagram.com/clown.shortfilm/`
        },
        otherCastAndCrews: [
            {
                castName: 'Adrian Ortega',
                image: missingImage,
                roles: ['Producer'],
                bio: `A small production house that loves art.`,
            },
            {
                castName: 'Aarushi Chowdhury',
                image: missingImage,
                roles: ['Producer'],
                bio: `A small production house that loves art.`,
            },
            {
                castName: 'Diane Vu',
                image: missingImage,
                roles: ['Screenwriter'],
                bio: `A small production house that loves art.`,
            },
            {
                castName: 'Deeya Saxena',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Shaina Chowdhury',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Alessia Boschini',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Marcus Cropp',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: {instagram: `https://www.instagram.com/madmanshooting/`}
            },
            {
                castName: 'Joshua Zhi Lee',
                image: missingImage,
                roles: ['Editor'],
                bio: {instagram: `https://www.instagram.com/vinayan_mj/`}
            },
            {
                castName: 'Matthew Shaw',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `N/A`
            },
            {
                castName: 'Tiffany Dias',
                image: missingImage,
                roles: ['Production Designer'],
                bio: {instagram: `https://www.instagram.com/sidhartha_pradeep/`}
            }
        ]
    },
    {
        filmName: "Father's Footsteps",
        castName: 'Matthew Daherty',
        image: missingImage,
        roles: ['Director', 'Producer', 'Screenwriter', 'Cinematographer', 'Editor'],
        quote: `N/A`,
        bio: `Matthew is a melbourne based filmmaker and academic.`,
        socialLinks: {
            instagram: ``
        },
        otherCastAndCrews: [
            {
                castName: 'Simin Dolatkhah',
                image: missingImage,
                roles: ['Director', 'Producer', 'Screenwriter', 'Production Designer'],
                bio: `A small production house that loves art.`,
            },
            {
                castName: 'Audres Smith',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Charlie Smith',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Thomas Smith',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Sofia Zikic',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Raky (Dog)',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `N/A`,
            }
        ]
    },
    // {
    //     filmName: "Father's Footsteps",
    //     name: 'Simin Dolatkhah',
    //     image: missingImage,
    //     roles: ['Director', 'Producer', 'Screenwriter', 'Production Designer'],
    //     quote: `N/A`,
    //     bio: ``,
    //     socialLinks: {
    //         instagram: ``
    //     },
    //     otherCastAndCrew: [
    //         {
    //             name: 'Matthew Daherty',
    //             image: missingImage,
    //             roles: ['Director', 'Producer', 'Screenwriter', 'Cinematographer', 'Editor'],
    //             bio: `Matthew is a melbourne based filmmaker and academic.`,
    //         },
    //         {
    //             name: 'Audres Smith',
    //             image: missingImage,
    //             roles: ['Lead Actor'],
    //             bio: `N/A`,
    //         },
    //         {
    //             name: 'Charlie Smith',
    //             image: missingImage,
    //             roles: ['Lead Actor'],
    //             bio: `N/A`,
    //         },
    //         {
    //             name: 'Thomas Smith',
    //             image: missingImage,
    //             roles: ['Supporting Actor'],
    //             bio: `N/A`,
    //         },
    //         {
    //             name: 'Sofia Zikic',
    //             image: missingImage,
    //             roles: ['Supporting Actor'],
    //             bio: `N/A`,
    //         },
    //         {
    //             name: 'Raky (Dog)',
    //             image: missingImage,
    //             roles: ['Supporting Actor'],
    //             bio: `N/A`,
    //         }
    //     ]
    // },
    {
        filmName: "Good Morning",
        castName: 'Anmol Arora',
        image: missingImage,
        roles: ['Director', 'Producer'],
        quote: `N/A`,
        bio: `N/A`,
        socialLinks: {
            instagram: ``
        },
        otherCastAndCrews: [
            {
                castName: 'Umang Shrivashtav',
                image: missingImage,
                roles: ['Screenwriter']
            },
            {
                castName: 'Ashlesha Thakur',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Petar Senwal',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Sumit Gulati',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `N/A`,
            },
            {
                castName: 'Ishan Sharma',
                image: missingImage,
                roles: ['Cinematographer']
            },
            {
                castName: 'Ajay Gupta',
                image: missingImage,
                roles: ['Editor']
            },
            {
                castName: 'Vinay Vaishnav',
                image: missingImage,
                roles: ['Editor']
            },
            {
                castName: 'Utam Ravat',
                image: missingImage,
                roles: ['Production Designer']
            }
        ]
    },
    {
        filmName: "A Robot's Dream",
        castName: 'Morteza Halimi',
        image: RobotDirector,
        roles: ['Director', 'Producer', 'Screenwriter', 'Editor'],
        quote: `N/A`,
        bio: ``,
        socialLinks: {
            blog: `https://mortezahalimi.com/film/film_14.html`
        },
        otherCastAndCrews: [
            {
                castName: 'Eon',
                image: missingImage,
                roles: ['Music Composer']
            },
            {
                castName: 'Nick Froud',
                image: missingImage,
                roles: ['Music Composer']
            },
            {
                castName: 'Moby Gratis',
                image: missingImage,
                roles: ['Music Composer']
            }
        ]
    },
    {
        filmName: "Holiday Email",
        castName: 'Ben Nixon',
        image: HEDirector,
        roles: ['Director', 'Cinematographer', 'Editor', 'Production Designer'],
        quote: `N/A`,
        bio: `Perth-based actor and film maker, specialising in stop motion. Studied at SAE Qantm.` ,
        socialLinks: {
            youtube: `@bluestaircaseanimations`, 
            instagram: `@runaway_balloon`
        },
        otherCastAndCrews: [
            {
                castName: 'Matt Nixon',
                image: missingImage,
                roles: ['Poducer'],
                bio: `Perth-based theatre writer, director and actor. Founder of Runaway Balloon Theatre Company.`
            },
            {
                castName: 'Rupert Williamson',
                image: missingImage,
                roles: ['Screenwriter'],
                bio: `Perth-based theatre writer, director and actor. Founder of Runaway Balloon Theatre Company.`
            },
            {
                castName: 'Tim Lorian',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Perth-based theatre director, writer and actor.`
            },
            {
                castName: 'Elsa Silberstein',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Theatre actor, writer and radio journalist.`
            }
        ]
    },
    {
        filmName: "Black Friday",
        castName: 'Jenna Milly',
        image: BlackFridayDirector,
        roles: ['Director', 'Screenwriter'],
        quote: `N/A`,
        bio: `Known for Golden Arm(2021) and The Fabulous Four(2024) starring Bette Midler and Susan Sarandon, Jenna is a writer, director, producer and went to UCL A Film School.` ,
        socialLinks: {
            instagram: `https://www.instagram.com/blackfridaytheshortfilm/`
        },
        otherCastAndCrews: [
            {
                castName: 'Andrew Dacesare',
                image: BlackFridayProducer,
                roles: ['Poducer'],
                bio: `Andrew has worked with Michael Bay and James Cameron. He is VP of Creative Development at 5150 Action.`
            },
            {
                castName: 'Jenna Milly & Ann Marie Allison',
                image: BlackFridayScreenwriter,
                roles: ['Screenwriter'],
                bio: `This writing team has developed movies from Sony, Netflix and many others and was named 25 Screenwriters to Watch by the Auctin Film Festival in 2021.`
            },
            {
                castName: 'Abbey McBride',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Abbey McBride is known for Veep.`
            },
            {
                castName: 'Bayne Gibby',
                image: BlackFridayActor_B,
                roles: ['Lead Actor'],
                bio: `Bayne Gibby is known for Lady Bird.`
            },
            {
                castName: 'Thomas Forbes-Johnson',
                image: BlackFridayActor_T,
                roles: ['Lead Actor'],
                bio: `Thomas Forbes-Johnson is known for The Loct City.`
            },
            {
                castName: 'Quincy Cho',
                image: BlackFridayActor_Q,
                roles: ['Lead Actor'],
                bio: `Quincy Cho is known for Jimmy Kimmel Live!`
            },
            {
                castName: 'Neil Watson',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: `Neil is from Puerto Rico and has worked on Avatar, Working Title and Rest Button.`
            },
            {
                castName: 'Noah Rosenstein',
                image: missingImage,
                roles: ['Editor']
            },
            {
                castName: 'Marisa Bertani',
                image: missingImage,
                roles: ['Production Designer']
            }
        ]
    },
    {
        filmName: "Ghost Town Cricket",
        castName: 'Matt Sterne',
        image: GhostTownCricketDirector,
        roles: ['Director', 'Screenwriter'],
        quote: `N/A`,
        bio: `https://filmfreeway.com/GhostTownCricket` ,
        socialLinks: {
            website: `www.mattsterne.com / www.uncoveredgrounds.com`
        },
        otherCastAndCrews: [
            {
                castName: 'Henry Richardson, ',
                image: GhostTownCricketProducer_H,
                roles: ['Poducer'],
                bio: `https://www.uncoveredgrounds.com/crew`
            },
            {
                castName: 'Bin Li',
                image: GhostTownCricketProducer_B,
                roles: ['Producer', 'Editor'],
                bio: `https://www.uncoveredgrounds.com/crew`
            },
            {
                castName: 'Sandy Mclellan',
                image: GhostTownCricketActor_S,
                roles: ['Lead Actor']
            },
            {
                castName: 'Hayden Guildford',
                image: GhostTownCricketActor_H,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Evan Papageorgiou',
                image: GhostTownCricketCinematographer,
                roles: ['Cinematographer']
            }
        ]
    },
    {
        filmName: "Let me Out",
        castName: 'Devanshi Srivastava',
        image: missingImage,
        roles: ['Director', 'Producer', 'Screenwriter', 'Cinematographer', 'Editor', 'Production Designer'],
        quote: `N/A`,
        bio: `An aspiring media student, who has a dream of becoming a reputed director one day.` ,
        socialLinks: {
            instagram: `Handle - @devanshii_srivastava`
        },
        otherCastAndCrews: [
            {
                castName: 'Amelie Devereux',
                image: LetMeOutLeadActor,
                roles: ['Lead Actor'],
                bio: `My name is Amélie Devereux, and I am an 18-year-old actress represented byJackie O Talent Agency, bringing a wealth
                of experience and a unique perspective to the world of performance.`
            },
            {
                castName: 'Claire Warrillow',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Claire is a highly experienced Voice Over artist, including commercial and audio books.
                She is a trained and diverse Musical Theatre actor and professional singer.`
            },
            {
                castName: 'Theofano Tsentikopoulou',
                image: LetMeOutSoundDesigner,
                roles: ['Music Composer'],
                bio: `Passionate about the art of sound in cinema, I'm Theofano, a recent graduate with a degree in
                Audio Production. With a specialised focus on creating immersive and captivating audio experiences for film.`
            }
        ]
    },
    {
        filmName: "Mind the Cart (Mann ka Thela)",
        castName: 'Madhu Dhurve',
        image: MindTheCartDirector_MD,
        roles: ['Director', 'Screenwriter', 'Music Composer'],
        quote: `N/A`,
        bio: `Madhu has been working with ektara collective in the process of making films for a decade.
        She started when she was very young as a volunteer and over the years has become an integral part of the
        ektara collective, participating in various assisting capacities including production design, direction etc. This
        is her first short film. 
        Outside of her work with the Ektara Collective, Madhu works as a fiction and non-fiction writer and has had
        several of her short stories and news reports published over the years. She also sings and performs with
        various groups.
        Her understanding of the world and the society is shaped by her own experiences as an indigenous person.
        Her unique voice foregrounds the experiences of people belonging to marginal backgrounds in her stories and
        also creates an aesthetic that is grounded in reality and is not manufactured. Her storytelling is emotive and
        political at the same time, giving the readers /viewers an opportunity to explore alternate perspectives. Her
        stories need to be told and she has the passion to do so both as a writer and a filmmaker.

        Narendra Singh Pardhi: Narendra has a keen interest in creating visuals that are localized and rooted in
        contextual realities. His sense of aesthetic is informed from his experiences as a person who belongs to the
        Pardhi denotified tribe bringing in details that are often unseen or ignored by the mainstream.
        Pushpa: Pushpa has been pursuing formal training in Audiography. Sound has played a major roles in her life
        and when she attended a workshop with Ektara Collective, she recognised her interest in sound in film. She
        belongs to a working class family residing in an industrial area in Central India with high levels of
        environmental and noise pollution. She is committed to bringing to the foreground the voices and stories of
        the people who have to live in these hard conditions through her work and art.
        Together, Madhu, Narendra and Pushpa are the second generation of the "Ektara Collective," an
        independent and autonomous group of individuals dedicated to merging creative efforts and imagination.
        Our aim is to collaborate with both trained and untrained individuals to produce fiction films that are
        rooted in our realities and experiences, both in terms of content and aesthetics. Having previously
        assisted in Ektara's films, we have now taken on the responsibility of creating our own short stories.` ,
        socialLinks: {
            instagram: `ektaracollective.in`
        },
        // otherCastAndCrew: [
        //     {
        //         name: 'Ektara collective',
        //         image: missingImage,
        //         roles: ['Producer'],
        //         bio: `As an independent filmmaking collective, Ektara brings together diverse individuals, trained and
        //         untrained, to merge their creative abilities and to make and produce films that are informed by and reflect a
        //         common reality that emerges from our experiences, both in terms of content and aesthetics. Ektara has
        //         successfully produced short fiction, feature films, music videos and has also supported the dubbing of films
        //         from various languages into Hindi.`
        //     },
        //     {
        //         name: 'Shakha Pawar',
        //         image: missingImage,
        //         roles: ['Lead Actor'],
        //         bio: `Shakha is studying in the 7th grade. He belongs to the “Pardhi community”. This tribe was
        //         criminalized in 1871 by britishers. In 1952, this law was denotified. Still they are struggling to get their
        //         basic needs met.`
        //     },
        //     {
        //         name: 'Rinku Uike',
        //         image: mtcSupporting,
        //         roles: ['Supporting Actor'],
        //         bio: `She is a 34 year old woman from the “Ojha Gond” tribe. She lives in Gautam Nagar slum of
        //         Bhopal and is a single mother. To raise her two children, she works as a waste collector and actively
        //         works with the community in the Urban Labour Organization for the rights of every person living in the
        //         slums.`
        //     },
        //     {
        //         name: 'Shini JK',
        //         image: mtcEditor,
        //         roles: ['Editor'],
        //         bio: `Born in Thalassery, Kerala. After pursuing Bachelors in Aeronautical Engineering in
        //         Coimbatore, Tamil Nadu, she worked as a freelance writer before landing up at Film and Television
        //         Institute of India, Pune to pursue Editing.
        //         After graduating from FTII in 2016, she shifted to Mumbai and started working as an Editor. She began
        //         her career in the film industry (March 2017) as an Assistant Director in Malayalam Feature film Eeda to
        //         know how things work on shoots at a large scale before the footage comes to the suite. After Eeda,
        //         since September 2017, her sole focus is on Editing. Primarily edited short fictions,documentaries,
        //         sketch series and music videos.`
        //     },
        //     {
        //         name: 'Sanjeev Shri',
        //         image: missingImage,
        //         roles: ['Music Composer'],
        //         bio: `Sanjeev Shri is a flamenco guitarist. He has learnt this art form for many years and has
        //         traveled to Spain just to learn this art form. He learnt flamenco from Juan de la Moron (Moron family of
        //         flamenco). He has also been trained in Indian classical. His style of playing is Indian classical
        //         influenced, sensitive and quite improvisational in nature.`
        //     }
        // ]
    },
    {
        filmName: "Mind the Cart (Mann ka Thela)",
        castName: 'Pushpa',
        image: missingImage,
        roles: ['Director'],
        quote: `N/A`,
        bio: `Pushpa has been pursuing formal training in Audiography. Sound has played a major roles in her life
        and when she attended a workshop with Ektara Collective, she recognised her interest in sound in film. She
        belongs to a working class family residing in an industrial area in Central India with high levels of
        environmental and noise pollution. She is committed to bringing to the foreground the voices and stories of
        the people who have to live in these hard conditions through her work and art.
        Together, Madhu, Narendra and Pushpa are the second generation of the "Ektara Collective," an
        independent and autonomous group of individuals dedicated to merging creative efforts and imagination.
        Our aim is to collaborate with both trained and untrained individuals to produce fiction films that are
        rooted in our realities and experiences, both in terms of content and aesthetics. Having previously
        assisted in Ektara's films, we have now taken on the responsibility of creating our own short stories.` ,
        socialLinks: {
            instagram: `ektaracollective.in`
        },
        // otherCastAndCrew: [
        //     {
        //         name: 'Ektara collective',
        //         image: missingImage,
        //         roles: ['Producer'],
        //         bio: `As an independent filmmaking collective, Ektara brings together diverse individuals, trained and
        //         untrained, to merge their creative abilities and to make and produce films that are informed by and reflect a
        //         common reality that emerges from our experiences, both in terms of content and aesthetics. Ektara has
        //         successfully produced short fiction, feature films, music videos and has also supported the dubbing of films
        //         from various languages into Hindi.`
        //     },
        //     {
        //         name: 'Shakha Pawar',
        //         image: missingImage,
        //         roles: ['Lead Actor'],
        //         bio: `Shakha is studying in the 7th grade. He belongs to the “Pardhi community”. This tribe was
        //         criminalized in 1871 by britishers. In 1952, this law was denotified. Still they are struggling to get their
        //         basic needs met.`
        //     },
        //     {
        //         name: 'Rinku Uike',
        //         image: mtcSupporting,
        //         roles: ['Supporting Actor'],
        //         bio: `She is a 34 year old woman from the “Ojha Gond” tribe. She lives in Gautam Nagar slum of
        //         Bhopal and is a single mother. To raise her two children, she works as a waste collector and actively
        //         works with the community in the Urban Labour Organization for the rights of every person living in the
        //         slums.`
        //     },
        //     {
        //         name: 'Shini JK',
        //         image: mtcEditor,
        //         roles: ['Editor'],
        //         bio: `Born in Thalassery, Kerala. After pursuing Bachelors in Aeronautical Engineering in
        //         Coimbatore, Tamil Nadu, she worked as a freelance writer before landing up at Film and Television
        //         Institute of India, Pune to pursue Editing.
        //         After graduating from FTII in 2016, she shifted to Mumbai and started working as an Editor. She began
        //         her career in the film industry (March 2017) as an Assistant Director in Malayalam Feature film Eeda to
        //         know how things work on shoots at a large scale before the footage comes to the suite. After Eeda,
        //         since September 2017, her sole focus is on Editing. Primarily edited short fictions,documentaries,
        //         sketch series and music videos.`
        //     },
        //     {
        //         name: 'Sanjeev Shri',
        //         image: missingImage,
        //         roles: ['Music Composer'],
        //         bio: `Sanjeev Shri is a flamenco guitarist. He has learnt this art form for many years and has
        //         traveled to Spain just to learn this art form. He learnt flamenco from Juan de la Moron (Moron family of
        //         flamenco). He has also been trained in Indian classical. His style of playing is Indian classical
        //         influenced, sensitive and quite improvisational in nature.`
        //     }
        // ]
    },
    {
        filmName: "Mind the Cart (Mann ka Thela)",
        castName: 'Narendra singh pardhi',
        image: MindTheCartDirector_NSP,
        roles: ['Director', 'Cinematographer'],
        quote: `N/A`,
        bio: `Narendra has a keen interest in creating visuals that are localized and rooted in
        contextual realities. His sense of aesthetic is informed from his experiences as a person who belongs to the
        Pardhi denotified tribe bringing in details that are often unseen or ignored by the mainstream. 
        One of the first filmmakers from the Pardhi community he is based in Bhopal, Madhya
        Pradesh and began his filmmaking journey in 2021. He has worked on three major films and two
        documentaries, focusing on editing, cinematography, and direction. As a member of the Ektara
        Collective, he has honed his skills through various opportunities. His dedication to his craft and
        community is evident in his work, establishing him as a promising talent in Hindi film and community
        filmmaker.`,
        socialLinks: {
            instagram: `ektaracollective.in`
        },
        otherCastAndCrews: [
            {
                castName: 'Ektara collective',
                image: missingImage,
                roles: ['Producer'],
                bio: `As an independent filmmaking collective, Ektara brings together diverse individuals, trained and
                untrained, to merge their creative abilities and to make and produce films that are informed by and reflect a
                common reality that emerges from our experiences, both in terms of content and aesthetics. Ektara has
                successfully produced short fiction, feature films, music videos and has also supported the dubbing of films
                from various languages into Hindi.`
            },
            {
                castName: 'Shakha Pawar',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Shakha is studying in the 7th grade. He belongs to the “Pardhi community”. This tribe was
                criminalized in 1871 by britishers. In 1952, this law was denotified. Still they are struggling to get their
                basic needs met.`
            },
            {
                castName: 'Rinku Uike',
                image: MindTheCartSupporting,
                roles: ['Supporting Actor'],
                bio: `She is a 34 year old woman from the “Ojha Gond” tribe. She lives in Gautam Nagar slum of
                Bhopal and is a single mother. To raise her two children, she works as a waste collector and actively
                works with the community in the Urban Labour Organization for the rights of every person living in the
                slums.`
            },
            {
                castName: 'Shini JK',
                image: MindTheCartEditor,
                roles: ['Editor'],
                bio: `Born in Thalassery, Kerala. After pursuing Bachelors in Aeronautical Engineering in
                Coimbatore, Tamil Nadu, she worked as a freelance writer before landing up at Film and Television
                Institute of India, Pune to pursue Editing.
                After graduating from FTII in 2016, she shifted to Mumbai and started working as an Editor. She began
                her career in the film industry (March 2017) as an Assistant Director in Malayalam Feature film Eeda to
                know how things work on shoots at a large scale before the footage comes to the suite. After Eeda,
                since September 2017, her sole focus is on Editing. Primarily edited short fictions,documentaries,
                sketch series and music videos.`
            },
            {
                castName: 'Sanjeev Shri',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `Sanjeev Shri is a flamenco guitarist. He has learnt this art form for many years and has
                traveled to Spain just to learn this art form. He learnt flamenco from Juan de la Moron (Moron family of
                flamenco). He has also been trained in Indian classical. His style of playing is Indian classical
                influenced, sensitive and quite improvisational in nature.`
            }
        ]
    },
    {
        filmName: "My Hands Are Shaking",
        castName: 'Natalia Sara Skorupa',
        image: MyHandsAreShakingDirector,
        roles: ['Director', 'Producer', 'Screenwriter', 'Lead Actor', 'Supporting Actor', 'Cinematographer', 'Editor', 'Music Composer', 'Production Designer'],
        quote: `N/A`,
        bio: `Natalia Sara Skorupa (she/her) – born in January 18, 1999 in Szczecin. Daughter of Beata and Jerzy,
        trained sociologist, filmmaker and curator. Pro- LGBTQ+ activist, singer and poet. In her artistic practices, she focuses
        on all kinds of audiovisual forms in the context of sexuality and willingly immerses herself in performative or
        documentary mediums, clearly exploring social, feminist, transgressive and radical topics using her own body. In 2022,
        she received a scholarship from the Minister of Culture and National Heritage, and in 2023, she received a scholarship
        from the Mayor of Szczecin.`,
        socialLinks: {
            instagram: `@artystka_ze_spalonego_teatruu`
        },
        otherCastAndCrews: []
    },
    {
        filmName: "The Garmentologist",
        castName: 'Debajit Banerjee',
        image: TheGarmentologistDirector,
        roles: ['Director', 'Producer', 'Screenwriter', 'Production Designer'],
        quote: `N/A`,
        bio: `https://www.djit.co.in`,
        socialLinks: {
            instagram: `https://www.djit.co.in`
        },
        otherCastAndCrews: [
            {
                castName: 'Rwik Dey',
                image: missingImage,
                roles: ['Lead Actor']
            },
            {
                castName: 'Ashok Roy',
                image: missingImage,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Souvik Basu',
                image: missingImage,
                roles: ['Cinematographer']
            },
            {
                castName: 'Abhishek Pradhan',
                image: missingImage,
                roles: ['Editor'],
                
            },
            {
                castName: 'Mainak Mazoomdar',
                image: missingImage,
                roles: ['Music Composer']
            }
        ]
    },
    {
        filmName: "Lilith",
        castName: 'Dale Crawford',
        image: LilithDirector,
        roles: ['Director', 'Producer', 'Screenwriter', 'Editor'],
        quote: `The film is a showcase of complex, heartfelt performances. After shooting the final scene William Emmons said that normally he'd never be the same.`,
        bio: `Dale was born in Melbourne, Australia. He studied screenwriting at Swinburne University, Fine Art painting and
        photography at the Victorian College of the Arts, Cinema Studies at LaTrobe University, Cultural Studies and
        Philosophy at the University of Melbourne, Art Direction at Award School and Masters of Multimedia Design at
        Monash University. He established Moon Room Films to write and direct his first feature film Lilith. 
        Lilith is the first feature film written and directed by Dale Crawford. Inspired by true stories and personal
        experiences the film was a labour of love. It was entirely self-funded and took ten years work to complete.`,
        socialLinks: {
            instagram: `www.dalecrawford.org`
        },
        otherCastAndCrews: [
            {
                castName: 'Robert Chuter',
                image: missingImage,
                roles: ['Producer'],
                bio: `Robert Chuter is an award-winning theatre director.`
            },
            {
                castName: 'Marie-Therese Biornerud',
                image: LilithLeadM,
                roles: ['Lead Actor'],
                bio: `Worsebehaviour.`
            },
            {
                castName: 'William Emmons',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `The Gardener.`
            },
            {
                castName: 'Timothy Quabba',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Doctor Blake Mysteries.`
            },
            {
                castName: 'Richard Aspel',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Blue Healers.`
            },
            {
                castName: 'Lila Abello',
                image: missingImage,
                roles: ['Lead Actor']
            },
            {
                castName: 'Akosia',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Thor: Love & Thunder`
            },
            {
                castName: 'Dale Crawford',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Le Brea`
            },
            {
                castName: 'Jet Tranter',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Occupation Rainfall`
            },
            {
                castName: 'Ryan Bown',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Home & Away`
            },
            {
                castName: 'Rudi Siira',
                image: LilithCinematographer,
                roles: ['Cinematographer'],
                bio: `Rudi Siira won the Golden Tripod Award from the Australian Cinematographers Society for the
                feature documentary Geeta and the Silver Tripod Award for the feature film Paper Champions.`
            },
            {
                castName: 'Michael Cooper',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `Michael Cooper is a multi-talented musician, producer, music director, composser and performer.`
            },
            {
                castName: 'Kim Triebsee',
                image: missingImage,
                roles: ['Production Designer'],
                bio: `Kim Triebsee is a Creative Director with a background in the fine arts, literature and fashion.`
            }
        ]
    },
    {
        filmName: "OPIA",
        castName: 'Cosmo Calman',
        image: OPIADirector,
        roles: ['Director', 'Screenwriter'],
        quote: `From humble beginnings to bittersweet ends, you can never be too sure “From humble beginnings to bittersweet ends, you can never be too sure
        how this thing we how this thing we call life goes. Film is just another form of expression. call life goes. Film is just another form of expression.
        But… I find myself lost in the challenge. But… I find myself lost in the challenge. I It’s not easy to go out and make t’s not easy to go out and make
        films and I find that a huge part of the allure. It’s difficult. It’s rewarding. films and I find that a huge part of the allure. It’s difficult. It’s rewarding.
        It’s a release. It’s everything you want it to be. It will punish you and it will It’s a release. It’s everything you want it to be. It will punish you and it will
        make you better. Some of the greatest moments aren’t scripted and that’s make you better. Some of the greatest moments aren’t scripted and that’s
        another thing I love. When people, cast or crew give you something of another thing I love. When people, cast or crew give you something of
        themselves that services the art. It grows and evolves into something far themselves that services the art. It grows and evolves into something far
        bigger.`,
        bio: `An emerging talent from the mighty Hawkes Bay. Cosmo cut his teeth in
        Wellington. A theatre technician by day and a freelance artist/writer by
        night. Cosmo studied at EIT Screen Production and Yoobee Colleges for a
        Bachelor of Animation, both times receiving awards for directing. With a
        few screenplays, a couple of novels and a comic book piled on his desk,
        several 48HR film fests and a handful of shorts under his belt, he has his
        sights set on the future of feature films. A few accolades for his
        screenplays, notably Storycamp 2022. Currently in pre-production he has
        another short with a wealth of writing material ready for publishing.
        Cosmo aims to keep his head down and foot on the gas.`,
        socialLinks: {
            instagram: `www.dreambig.net.nz or #Opia2023hb or #danielbettynz`
        },
        otherCastAndCrews: [
            {
                castName: 'Daniel Betty',
                image: OPIAProducer,
                roles: ['Producer', 'Production Designer'],
                bio: `Is a producer and director throughout New Zealand and Australia. He
                graduated with a Bachelor of Performing Arts from Unitec and has a
                post graduate diploma in secondary teaching from Massey university.
                
                Upon returning to Hawke’s Bay Daniel has been at the birth of many great
                artistic initiatives. He championed community and education in the
                Hawke’s Bay Arts Festival and Toitoi Hawke’s Bay Arts and Events Centre.
                He assisted in the establishment of Eastern Screen Alliance and
                developed Screen Central a collaborative hub for creative people. He sits
                on the Nga Toi Board the arts council for Hawke’s Bay and is passionate
                about building opportunities for creatives.
                
                Currently he is the Creative Director for Dream Big a production company
                that works on screen, on stage and on air. They have a social focus and
                champion creativity as a vehicle for social change.`
            },
            {
                castName: 'Yvonne Lorkin',
                image: missingImage,
                roles: ['Producer'],
                bio: `While Yvonne Lorkin is best known for her work in New Zealand’s wine
                media (Dish Magazine/NZ Herald/WineFriend.co.nz), prior to that her life
                revolved around the music industry, 95bFM, MTV, managing bands and in
                the production and publicity of tours, concerts and music videos. She’s a
                stage and screen actor, and the founder of major Auckland-based
                voiceover talent agency Bigmouth. She also founded Thirsty Media, a
                production company responsible for creating high-end food and wine
                video content for independent clients and the popular lifestyle TV series
                Thirsty Work (FoodTV + ThreeNow) which she produced, wrote and
                presented.
                
                A proud Hawkesbaylien, Yvonne has loved working on this art-soaked
                project with its incredibly talented local crew, and she’s excited to see the
                great relationships now being formed between local businesses and
                Hawke’s Bay’s screen industry professionals.`
            },
            {
                castName: 'Maia Bassett',
                image: OPIALead,
                roles: ['Lead Actor'],
                bio: `Mantra`
            },
            {
                castName: 'Noah Kaio',
                image: OPIASupport,
                roles: ['Supporting Actor'],
                bio: `The Stranger`
            },
            {
                castName: 'Gilly Lawrence',
                image: missingImage,
                roles: ['Cinematographer']
            },
            {
                castName: 'Frayne Dyke-Walker',
                image: missingImage,
                roles: ['Editor']
            },
            {
                castName: 'Hone Kawana Matene',
                image: missingImage,
                roles: ['Music Composer']
            },
            {
                castName: 'Halo Siloata',
                image: missingImage,
                roles: ['Music Composer']
            }
        ]
    },
    {
        filmName: "The Wandering Island",
        castName: 'Pablo Borges',
        image: missingImage,
        roles: ['Director', 'Screenwriter', 'Editor'],
        quote: `N/A`,
        bio: `Pablo Broges is a filmmaker and photojournalist born in Santa Cruz de Tenerife (Spain), in 1997.`,
        socialLinks: {
            instagram: `@paboborgesdl, @digital104_filmdistribution`

        },
        otherCastAndCrews: [
            {
                castName: 'Patricia Marti',
                image: missingImage,
                roles: ['Producer']
            },
            {
                castName: 'José Luis de Madariaga',
                image: missingImage,
                roles: ['Lead Actor']
            },
            {
                castName: 'Sergio Hernández',
                image: missingImage,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Sergio Herrera',
                image: missingImage,
                roles: ['Cinematographer']
            },
            {
                castName: 'Ricardo Gonzáles',
                image: missingImage,
                roles: ['Music Composer']
            },
            {
                castName: 'Gabriel Vega',
                image: missingImage,
                roles: ['Production Designer']
            }
        ]
    },
    {
        filmName: "The Piccadilly - Restoring Her Former Glory",
        castName: 'Matthew Shannon',
        image: ThePicadillyDirector,
        roles: ['Director'],
        quote: `N/A`,
        bio: `Matt has a Bachelor of Arts Degree in Film, American and European History. He is a member of the Australian Directors Guild (ADG),
        Australian Editors Guild (ASE) and Australian Cinematographers Society. Matthew is an award-winning Director, Cinematographer and
        Editor.`,
        socialLinks: {
            instagram: `www.scarlettmedia.com.au`
        },
        otherCastAndCrews: [
            {
                castName: 'Peta Shannon',
                image: ThePicadillyProducer,
                roles: ['Producer'],
                bio: `Peta is an award-winning Producer, Writer, Actor, Dancer and with Screen Producers Association (SPA), Australian Writers' Guild (AWG) and
                Media Resource Centre memberships with a Bachelor of Arts Degree in Drama, Film and Creative Writing.`
            },
            {
                castName: 'Scott Hicks',
                image: ThePicadillyLead_Sch,
                roles: ['Lead Actor'],
                bio: `Scott Hicks: https://www.imdb.com/name/nm0382956/`
            },
            {
                castName: 'Sophie Hyde',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Sophie Hyde: https://www.imdb.com/name/nm1170520/?ref_=fn_al_nm_1`
            },
            {
                castName: 'Tilda Cobham-Hervey',
                image: ThePicadillyLead_Tch,
                roles: ['Lead Actor'],
                bio: `Tilda Cobham-Hervey: https://www.imdb.com/name/nm5315479/?ref_=fn_al_nm_1`
            },
            {
                castName: 'Maxx Corkindale',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: `Maxx Corkindal: https://www.imdb.com/name/nm2663932/?ref_=fn_al_nm_1`
            },
            {
                castName: 'Matthew Shannon',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: `Matthew Shannon: https://www.imdb.com/name/nm6367066/`
            },
            {
                castName: 'Dave Gregan',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: `Dave Gregan: https://www.imdb.com/name/nm2962529/?ref_=ttfc_fc_cr`
            },
            {
                castName: 'Nic Begue',
                image: missingImage,
                roles: ['Editor'],
                bio: `Nicholas Begue: https://www.imdb.com/name/nm14240026/?ref_=ttfc_fc_cr`
            },
            {
                castName: 'Matthew Shannon',
                image: missingImage,
                roles: ['Editor']
            }
        ]
    },
    {
        filmName: "Sole Sista",
        castName: 'Emerie Boone',
        image: SoleSistaDirector,
        roles: ['Director', 'Screenwriter', 'Production Designer'],
        quote: `N/A`,
        bio: `Emerie is an 18 year old Australian/American director based in Sydney, Australia. She brings passion, enthusiasm and her own acting experience
        (as seen in her performance on ABC iview's TV show 'The PM's Daughter') to every project and has an incredible knack for bringing her vision to life
        on camera.`,
        socialLinks: {
            instagram: `@blackgirlsfilmcamp`
        },
        otherCastAndCrews: [
            {
                castName: 'Black Girls Film Camp',
                image: missingImage,
                roles: ['Producer'],
                bio: `Black Girls Film Camp, founded in 2021, is a non-profit organisation that sponsors young black female
                directors in creating their dream films across the world.`
            },
            {
                castName: 'Ashyln Ballard',
                image: SoleSistaLead,
                roles: ['Lead Actor'],
                bio: `Ashlyn Ballard is an 17 year old actress from the state of California. Ashlyn is a hardworking and
                talented individual who has a deep love for acting. She brings flare and creativity to every roles she's in.`
            },
            {
                castName: 'Clarissa Martinez Del Rio',
                image: SoleSistaSupporting_CM,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Sage Olguin',
                image: missingImage,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Chloe Voigt',
                image: missingImage,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Shonda Porter',
                image: SoleSistaSupporting_SP,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Tenika Pounce',
                image: SoleSistaSupporting_TP,
                roles: ['Supporting Actor']
            },
            {
                castName: 'Christine Wiggins',
                image: SoleSistaSupporting_CW,
                roles: ['Supporting Actor']
            },
            {
                castName: 'B.P. Edwards',
                image: SoleSistaCinematographer,
                roles: ['Cinematographer'],
                bio: `B.P. Edwards is a multi-hyphenate filmmaker, creative and entrepreneur from Pasadena, CA. B.P. was selected as a
                cinematographer for the inaugural Cohort of Color Creative's Find Your People Program.`
            },
            {
                castName: 'Jordan Knight',
                image: SoleSistaEditor,
                roles: ['Editor'],
                bio: `Jordan Knight is a KSU (Kennesaw State University) 2024 Graduate. She is dedicated to her craft and
                always actioning forward on how she can develop and positively impact her field of work as an editor.`
            },
            {
                castName: 'Georges Bizet',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `Georges Bizet was a French composer of the Romantic era. Best known for his operas.`
            },
            {
                castName: 'Pyotr Ilyich Tchaikovsky',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `Pyotr Ilyich Tchaikovsky was a Russian composer during the Romantic period. He was the first Russian composer whose music would make a lasting impression internationally.`
            },
            {
                castName: 'Antonio Vivaldi',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `Antonio Lucio Vivaldi was an Italian composer, virtuoso violinist and impresario of Baroque music.`
            }
        ]
    },
    {
        filmName: "Papa Aur Smartphone",
        castName: 'Raj Sisodiaa',
        image: missingImage,
        roles: ['Director', 'Screenwriter', 'Production Designer'],
        quote: `N/A`,
        bio: `A young professional who hails from the spiritual city of lord krishna, Mathura, Uttar Pradesh, Raj has always thought about making a difference by his films, Raj Sisodiaa had started his career as an assistant director in movies like Tevar, Bhaagte raho before he got a chance to become the first assistant Director in the movie, Bhuj- The pride of india, starring, Ajay Devgn, Sanjay Dutt, Sonakshi Sinha and others. Raj has a habit of writing that makes him more lethal and creative as a director.`,
        socialLinks: {
            instagram: `https://www.instagram.com/officialrajsisodiaa/`,
            facebook: `https://www.facebook.com/profile.php?id=100008151524460`,
            linkedin: `https://www.linkedin.com/in/raj-sisodiaa-7a9577134/?originalSubdomain=in`
        },
        otherCastAndCrews: [
            {
                castName: 'Vandana Mishra',
                image: missingImage,
                roles: ['Producer'],
                bio: `She is a first time producer.`
            },
            {
                castName: 'Sanjay Mishra',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Sanjay Mishra is an Indian actor primarily working in the Hindi film and television industry. Mishra won the Best Actor award in the critic's category for Aankhon Dekhi at the Filmfare Awards in 2015. He was nominated for Masaan and Kamyab in the following years. He went on to win awards as the best actor for Aankhon Dekhi in 2014 and best-supporting actor for Masaan in 2015 at the Filmfare awards. He also received the Best Supporting actor (Male) award for Masaan at the Zee Cine Awards in 2016.`
            },
            {
                castName: 'Shriidhar Dubey',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Shriidhar Dubey was born in Varanasi, Uttar Pradesh, India. He is known for Gangs of Wasseypur (2012), Raat Akeli Hai (2020) and Bholaa (2023).`
            },
            {
                castName: 'Anil Pingua',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: `Anil Pingua is known for Papa Aur Smartphone and After That (Uske Baad) (2022).`
            },
            {
                castName: 'Vinay Pal',
                image: missingImage,
                roles: ['Editor'],
                bio: `Vinay Pal is known for Jazbaa (2015), Yeh Hai India (2017) and Action Jackson (2014).`
            },
            {
                castName: 'Justin Jose',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `Justin Jose is known for Minnal Murali (2021), Bajirao Mastani (2015) and Uri: The Surgical Strike (2019).`
            },
            {
                castName: 'Krishna Thakur',
                image: missingImage,
                roles: ['Production Designer'],
                bio: `Krishna Thakur is an Art Director and Production Designer based at Mumbai. Krishna Thakur is known for The Kashmir File, Holiday (2014), Badlapur (2015), The Second Best Exotic Marigold Hotel (2015) and Chhichhore (2019) And many more.. He has been working since year 2011, in the Bollywood industry. He is a post graduate from the prestigious Film and Television Institute of India FTII class of 2009- 2011, he has established himself as a prominent figure in the world of cinema, Specialising in the roles of Art Director and Production Designer. He has creative expertise in a diverse range of projects, including feature films, web series, and compelling advertising campaigns.`
            }
        ]
    },
    {
        filmName: "WE DON'T SAY RETARD ANYMORE",
        castName: 'Tez Frost',
        image: missingImage,
        roles: ['Director', 'Producer', 'Screenwriter', 'Editor'],
        quote: `Cinema is a vast canvas, and each narrative serves as a vibrant hue. With "We Don’t Say Retard Anymore", I hope to spotlight those colours that have been too often muted, bringing them into the vibrant landscape of our shared storytelling.

        "We Don’t Say Retard Anymore," embraces unconventional casting with a lead actor with Tourette syndrome and an albino artist, amplifying voices often unheard, and heralding a new era of diverse representation in cinema.
        
        Being Australian-Chinese has allowed me to function as a bridge between cultures. From teaching English in Asia to collaborating with industry giants like Disney China, I have endeavoured to challenge the status quo and create a space for diverse narratives.
        
        "We Don’t Say Retard Anymore" represents a beacon of resilience, and echoes my belief that limitations can be turned into opportunities. This ethos was exemplified by my Guinness World Record breaking film "Little Hands," produced entirely remotely during the COVID-19 pandemic.
        
        The release of "We Don’t Say Retard Anymore" is about more than unveiling a new film; it's a stride towards a vision of a more inclusive world. It's a statement that diversity and representation should not be exceptions, but norms.
        
        My hope is that this shared sense of discovery translates to the audience, offering a viewing experience as refreshing and exciting as it was during its creation.`,
        bio: `Tez Frost is a Chinese-Australian director, known for breaking a Guinness World Record for his renowned short film, "Little Hands." Celebrated for its universality, "Little Hands" achieved a record for being the most translated short film, reaching audiences in 21 different languages.

        His films have screened at international festivals such as Frameline: San Francisco International Film Festival and Adelaide Film Festival and has won awards including the Jury Prize at the Las Vegas International Film Festival. Tez is currently working out of his home city, Melbourne.`,
        socialLinks: {
            facebook: `https://www.facebook.com/wedontsayretardanymore`
        },
        otherCastAndCrews: [
            {
                castName: 'Pekka Luodeslampi',
                image: WDSRALead,
                roles: ['Lead Actor'],
                bio: `Guinness World Record breaking disabled performer.`
            },
            {
                castName: 'Courtney McCrea',
                image: WDSRASupporting,
                roles: ['Supporting Actor'],
                bio: `Cosplay specialist from Northern Ireland.`
            },
            {
                castName: 'Aaro Alhonen',
                image: missingImage,
                roles: ['Cinematographer']
            },
            {
                castName: 'Markus Kellow',
                image: missingImage,
                roles: ['Music Composer']
            }
        ]
    },
    {
        filmName: "Vanavaa",
        castName: 'Mr. Anup Nilkumar Jatratkar',
        image: missingImage,
        roles: ['Director', 'Screenwriter'],
        quote: `N/A`,
        bio: `Directed more than 30 short films. Just release one marathi film named 'Gaabh'.`,
        socialLinks: {
            instagram: `www.anupjatratkar.com`
        },
        otherCastAndCrews: [
            {
                castName: 'Mr. Machhindra Baban Kamble',
                image: missingImage,
                roles: ['Producer']
            },
            {
                castName: 'Dr. Pramod Jyotiram kasabe',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Assistant Professor - Manoscrence & Bio`
            },
            {
                castName: 'Mr. Ananda Hable',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `Actor, Anti-addiction campaigner.`
            },
            {
                castName: 'Mr. Anup Nilkumar Jatratkar',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: `As given in the back.`
            },
            {
                castName: `Priydarshan's Atelier Nipani`,
                image: missingImage,
                roles: ['Editor'],
                bio: `An Audio & Video studio.`
            }
        ]
    },
    {
        filmName: "Raj Kapoor's Loni Tunes",
        castName: 'Nadir Ahmed',
        image: missingImage,
        roles: ['Director'],
        quote: `N/A`,
        bio: `Its his first film.`,
        socialLinks: {
            instagram: `Djproductions.co.in`
        },
        otherCastAndCrews: [
            {
                castName: 'Devendra Shivaji Jadhav',
                image: missingImage,
                roles: ['Producer'],
                bio: `diproductions.co.in`
            },
            {
                castName: 'Toshik',
                image: missingImage,
                roles: ['Cinematographer']
            },
            {
                castName: 'Raunak',
                image: missingImage,
                roles: ['Cinematographer']
            }
        ]
    },
    {
        filmName: "WHERE I AM",
        castName: 'Nadir Ahmed',
        image: missingImage,
        roles: ['Director'],
        quote: `N/A`,
        bio: `Its his first film.`,
        socialLinks: {
            instagram: `Djproductions.co.in`
        },
        otherCastAndCrews: [
            {
                castName: 'Devendra Shivaji Jadhav',
                image: missingImage,
                roles: ['Producer'],
                bio: `diproductions.co.in`
            },
            {
                castName: 'Toshik',
                image: missingImage,
                roles: ['Cinematographer']
            },
            {
                castName: 'Raunak',
                image: missingImage,
                roles: ['Cinematographer']
            }
        ]
    },
    /*Wil updated from here*/ 
    {
        filmName: "Why Wait",
        castName: 'Marie-hélène Grenier',
        image: missingImage,
        roles: ['Director', 'Screenwriter'],
        quote: `N/A`,
        bio: `N/A`,
        socialLinks: {
            website: `2squarefilms.com`
        },
        otherCastAndCrews: [
            {
                castName: 'Stéphane Landry',
                image: missingImage,
                roles: ['Producer'],
                bio: 'Producer since 2005',
            },

            {
                castName: 'Pierre Alexandre Coté',
                image: missingImage,
                roles: ['Cinematographer'],
            },
            {
                castName: `Josée Trotier`,
                image: missingImage,
                roles: ['Editor'],
            }
        ]
    },
    {
        filmName: "The pod",
        castName: 'MNaveen Shukla',
        image: ThePodDirector,
        roles: ['Director', 'Screenwriter','Producer','Cinematographer','Editor','Production Designer'],
        quote: `N/A`,
        bio: `N/A`,
        socialLinks: {
            instagram: ``
        },
        otherCastAndCrews: [
            {
                castName: 'Hem Tiwary',
                image: ThePodProducer,
                roles: ['Producer', 'Lead Actor'],
            },
            {
                castName: 'Jaya Berged',
                image: missingImage,
                roles: ['Supporting Actor'],

            },
            {
                castName: 'Kaveri Tiwary',
                image: missingImage,
                roles: ['Supporting Actor'],
            },
        ]
    },
    {
        filmName: "The Hero Of Adelaide",
        castName: 'Zakariya Muhammad Ilias',
        image: TheHeroOfAdelaideDirector,
        roles: ['Director', 'Screenwriter', 'Producer','Cinematographer', 'Editor', 'Production Designer'],
        quote: `N/A`,
        bio: `
            17-year-old Australian homeschooler, loves filmmaking, carving wood, and eating food.
            When an agent breaks into the home of none other than Dr. Alfred in an attempt to steal some valuable information, 
            but what he stumbles on changes everything.
        `,
        socialLinks: {
            youtube: `@DMSProductions-hc9qp`
        },
        otherCastAndCrews: [
            {
                castName: 'Reeve Jansen',
                image: TheHeroOfAdelaideLead,
                roles: ['Lead Actor'],
                bio: `15 years old, passionate about acting for film, he also enjoys playing vieo games, writing, and editing.`
            },
            {
                castName: 'Jake Mccormack',
                image: TheHeroOfAdelaideSupporting,
                roles: ['Supporting Actor'],
                bio :'14 years old, multi award winning filmmaker, and is intrested in acting as a career. '
            },
            {
                castName: 'Karmi Wingfield',
                image: TheHeroOfAdelaideCinema,
                roles: ['Cinematographer'],
                bio: `Passionate photographer, studying makeup, and enjoys making short films.`
            },
        ]
    },
    {
        filmName: "Sex For Survival",
        castName: 'Jorge Antonio Gonzalez',
        image: missingImage,
        roles: ['Director'],
        quote: `N/A`,
        bio: `
            Director of more than 30 short documentaries and winner of 18 Suncoast Emmy Awards.
            More than 25 years of experience in the industry. Nominated for the 2024 Goya Awards as 
            Producer of the film SIMÓN (top 10 worldwide on Netflix and winner of 20 international awards).
        `,
        socialLinks: {
            instagram: `@SEXOPORSUPERVIVENCIA`
        },
        otherCastAndCrews: [
            {
                castName: 'Luis Olavarrieta',
                image: missingImage,
                roles: ['Lead Actor'],
                bio: `Very well-known Venezuelan journalist with 4 million followers on Instagram. Suncoast Emmy winner for other documentaries.`
            },
            {
                castName: 'Boris Urrutia',
                image: missingImage,
                roles: ['Editor'],
                bio: `Award-winning editor and director with more than 25 years of experience. Winner of Emmy International, Promax/BDA, and Telly Awards.`
            }
        ]
    },
    {
        filmName: "Parole",
        castName: 'Param Walia',
        image: ParoleDirector,
        roles: ['Director', 'Screenwriter',],
        quote: `N/A`,
        bio: `
            Param Walia is an Australian film director and writer of Indian origin who hails from Kota, Rajasthan, India.
            Writer/Director - (Phantasm, Sweet Tooth)
            Assistant Director - (My Melbourne - Feature)
            Executive Producer - The Last Surprise
        `,
        socialLinks: {
            instagram: 'https://www.instagram.com/iparamwalia/'
        },
        otherCastAndCrews: [
            {
                castName: 'Mitchell Holland',
                image: missingImage,
                roles: ['Lead Actor'],
            },
            {
                castName: 'Christopher Kirby',
                image: missingImage,
                roles: ['Supporting Actor'],
            },
            {
                castName: 'Nicholaus Burkitt',
                image: missingImage,
                roles: ['Cinematographer'],
            },
            {
                castName: 'Andrew Smith',
                image: missingImage,
                roles: ['Editor'],
            },
            {
                castName: 'Joel Armour',
                image: missingImage,
                roles: ['Music Composer'],
            },
            {
                castName: 'Anh Vuong',
                image: missingImage,
                roles: ['Production Designer'],
            }
        ]
    },
    {
        filmName: "B for naoo",
        castName: 'Roohi Kashfi',
        image: BForNaooDirector,
        roles: ['Director', 'Producer', 'Screenwriter'],
        quote: `N/A`,
        bio: `
            Award-winning filmmaker from the Federally Administered Tribal Areas (FATA) of Pakistan.
            Graduate of New York Film Academy Australia and National College of Arts Lahore, Pakistan.
        `,
        socialLinks: {
            instagram: 'https://www.instagram.com/roohikashfi?igsh=ZDE5aHZpNTU5Z3ls&utm_source=qr',
            facebook: 'https://www.facebook.com/roohikashfiofficial?mibextid=LQQJ4d'
        },
        otherCastAndCrews: [
            {
                castName: 'Sajjad Gohar',
                image: missingImage,
                roles: ['Cinematographer', 'Production Designer'],
                bio: `Visual communication designer and production designer.`
            },
            {
                castName: 'Ahmed Ali Shah',
                image: missingImage,
                roles: ['Editor'],
                bio: `Pakistani film director, writer, and editor. Owner of Frontrow Studio with several national and international commercial projects. Currently working with HUM TV as a producer.`
            },
            {
                castName: 'Qais Ahmed Kashfi',
                image: missingImage,
                roles: ['Music Composer'],
                bio: `
                    Musician, recording engineer, lyricist, composer, and rapper. 
                    Currently working as a sound engineer at Coke Studio, Pakistan.
                `
            },
            {
                castName: 'Mehdi Hussain',
                image: missingImage,
                roles: ['Supporting Actor'],
                bio: `
                    1st time actor. He has written, composed, and sung many music projects.
                    Actor/filmmaker from FATA Tribal areas of Pakistan.
                `
            },
            {
                castName: 'Ziyad Ahmed Tariq',
                image: missingImage,
                roles: ['Production Designer'],
                bio: `
                    U.S.A based film director, originally from Karachi, Pakistan. 
                    Worked on several national and international projects.
                `
            },
            {
                castName: 'Raja Roy Diyach Thebo',
                image: missingImage,
                roles: ['Cinematographer'],
                bio: `Owner of Frontrow Studio. He has done several commercial projects nationally and internationally.`
            }
        ]
    },
];


type MovieDict = {
    [key: string]: {
        movieName: string;
        casts: Partial<MovieCast>[];
    };
};

const createMoviesCastList = (casts: MovieCast[]) => {
    if (!Array.isArray(casts)) {
        console.error("casts is not an array or is undefined");
        return [];
    }

    // Create a dictionary to merge movies by name
    const movieDict: MovieDict = {};

    casts.forEach(movie => {
        if (!movieDict[movie.filmName]) {
            movieDict[movie.filmName] = {
                movieName: movie.filmName,
                casts: []
            };
        }

        // Add the main cast (e.g., Director, Producer, etc.)
        if (movie.castName && Array.isArray(movie.roles)) {
            movieDict[movie.filmName].casts.push({
                castName: movie.castName,
                roles: movie.roles.join(", "),
                bio: movie.bio || '',
                image: movie.image || ''
            });
        } else {
            console.warn(`Missing name or roles for the movie: ${movie.filmName}`);
        }

        // Add the other cast and crew members
        if (Array.isArray(movie.otherCastAndCrews)) {
            movie.otherCastAndCrews.forEach(castMember => {
                movieDict[movie.filmName].casts.push({
                    castName: castMember.castName,
                    roles: Array.isArray(castMember.roles) ? castMember.roles.join(", ") : castMember.roles,
                    bio: castMember.bio || '',
                    image: castMember.image || ''
                });
            });
        }
    });

    // Convert the dictionary back into an array
    return Object.values(movieDict);
};



export default casts;
export { createMoviesCastList };

